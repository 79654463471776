/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import {
  Table,
  Row,
  Col,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  CustomInput,
  FormGroup,
  Label,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
} from "reactstrap";
import axios from "axios";
import * as dateFns from "date-fns";
import Select from "react-select";
import DatePicker from "react-datepicker";
import confirm from "reactstrap-confirm";
import uuid from "uuid";
import AuthenticateModal from "../Authenticate/Authenticate.modal.component";
import { authService } from "services/Permission.service.js";

class StudentEnroll extends React.Component {
  constructor(props) {
    super(props);

    this.state = this.getModalState();
  }

  getModalState = () => {
    return {
      students: [],
      selectedStudentIds: [],
      selectedSemesterId: "",
      semesters: [],
      selectedCourseId: "",
      selectedPeriod: "",
      classes: [],
      selectedClassId: "",
      courses: [],
      periods: [],
      firstDayOfClass: null,
      selectedSubjects: [],
      modalAuthenticateIsOpen: false,
      modalAuthenticateMessage: "",
      modalAuthenticateNote: "",
    };
  };

  onCloseModal = () => {
    this.resetModal();
  };

  resetModal = () => {
    return new Promise((resolve, reject) => {
      this.setState(this.getModalState(), resolve(true));
    });
  };

  refresh() {
    return new Promise((resolve, reject) => {
      let courseId = this.props.courseId;
      let classId = this.props.classId;
      let periodId = this.props.periodId;
      let statusDate = dateFns.format(this.props.dateSearch, "yyyy-MM-dd");
      let campusId = this.props.campusId;
      let sevisId = this.props.sevisId;

      if (periodId === "all") periodId = "";
      if (courseId === "all") courseId = "";
      if (classId === "all") classId = "";
      if (campusId === "all") campusId = "";
      if (sevisId === "all") sevisId = "";

      let statusTypeList = [];
      this.props.selectedStatusTypes.map((statusType, index) =>
        statusTypeList.push({
          Id: statusType.id,
        })
      );

      let getAllParam = {
        courseId: courseId,
        classId: classId,
        period: periodId,
        statusTypeList: statusTypeList,
        statusDate: statusDate,
        campusId: campusId,
        sevisId: sevisId,
        page: 1,
        pageSize: 999999999,
      };

      axios.post("students/getall", getAllParam).then((response) => {
        let students = [];

        response.data.data.items.map((student, index) => {
          students.push({
            id: student.id,
            name: student.name,
          });
        });

        this.setState({ students: students }, resolve(true));
      });
    });
  }

  onOpened() {
    this.refresh().then(() => {
      this.refreshSemesters().then(() => {
        this.refreshCourses();
      });
    });
  }

  setStudentId = (id) => {
    let selectedStudentIds = [];

    if (this.state.selectedStudentIds.find((x) => x === id)) {
      selectedStudentIds = this.state.selectedStudentIds.filter(
        (x) => x !== id
      );

      this.setState({ selectedStudentIds: selectedStudentIds });
    } else {
      selectedStudentIds = this.state.selectedStudentIds;
      selectedStudentIds.push(id);

      this.setState({ selectedStudentIds: selectedStudentIds });
    }
  };

  refreshSemesters = () => {
    return new Promise((resolve, reject) => {
      let semesters = [];
      this.props.tables.semesters.map((semester, index) =>
        semesters.push({
          value: semester.id,
          label: semester.name,
          startDate: semester.startDate,
          endDate: semester.endDate,
        })
      );

      this.setState({ semesters: semesters }, resolve(true));
    });
  };

  handleSemesterEnrollChange = (selectedOption) => {
    this.setState(
      { selectedSemesterId: selectedOption.value, selectedClassId: "" },
      () => {
        this.refreshClasses();
      }
    );
  };

  refreshClasses = () => {
    return new Promise((resolve, reject) => {
      let courseId = this.state.selectedCourseId;
      let periodId = this.state.selectedPeriod;
      let semesterId = this.state.selectedSemesterId;

      axios
        .get(
          "classes?courseId=" +
            courseId +
            "&period=" +
            periodId +
            "&semesterId=" +
            semesterId +
            "&page=1&pageSize=999999"
        )
        .then((response) => {
          let classes = [];

          response.data.data.items.map((classe, index) =>
            classes.push({
              value: classe.id,
              label: classe.code,
              campusName: classe.campus.name,
              semesterName: classe.semester.name,
              courseName: classe.course.name,
              isCurrentSemester: classe.semester.current,
              semesterStartDate: classe.semester.startDate,
              teacherName: classe.teacher.name,
            })
          );

          this.setState({ classes: classes }, resolve(true));
        });
    });
  };

  handleClassChange = (selectedOption) => {
    this.setState({ selectedClassId: selectedOption.value });
  };

  refreshCourses = () => {
    return new Promise((resolve, reject) => {
      let courses = [];

      this.props.tables.courses.map((classe, index) =>
        courses.push({
          value: classe.id,
          label: classe.name,
        })
      );

      this.setState({ courses: courses }, resolve(true));
    });
  };

  handleCourseChange = (selectedOption) => {
    this.setState(
      { selectedCourseId: selectedOption.value, selectedClassId: "" },
      () => {
        this.refreshClasses();
      }
    );
  };

  periodOptions = [
    { value: "M", label: "Morning" },
    { value: "E", label: "Evening" },
    { value: "MT", label: "Mon/Tue" },
    { value: "WT", label: "Wed/Thu" },
    { value: "HW", label: "Wed" },
  ];

  handlePeriodChange = (selectedOption) => {
    this.setState(
      { selectedPeriod: selectedOption.value, selectedClassId: "" },
      () => {
        this.refreshClasses();
      }
    );
  };

  handleFirstDayOfClassChange = (date) => {
    this.setState({ firstDayOfClass: date });
  };

  getSelectedSemester = () => {
    let semesterId = this.state.selectedSemesterId;
    return this.state.semesters.find((x) => x.value === semesterId);
  };

  async addSubject(e) {
    let modalState = this.state;

    if (modalState.selectedClassId) {
      if (modalState.selectedSubjects.length >= 4) {
        confirm({
          title: <>Warning</>,
          message: "Four subjects have already been selected.",
          cancelText: null,
        });
      } else if (
        modalState.selectedSubjects.filter(
          (e) => e.value === modalState.selectedClassId
        ).length > 0
      ) {
        confirm({
          title: <>Warning</>,
          message: "The selected subject has already been selected.",
          cancelText: null,
        });
      } else {
        let subject = modalState.classes.find(
          (x) => x.value === modalState.selectedClassId
        );

        subject.id = uuid.v4();
        subject.startDate = modalState.firstDayOfClass;

        modalState.selectedSubjects.unshift(subject);
        modalState.selectedClassId = "";

        this.setState(modalState);
      }
    }
  }

  async classDelete(id) {
    let result = await confirm({
      title: <>Warning</>,
      message: "Do you want to delete this subject?",
    });

    if (result) {
      let modalState = this.state;
      let subject = modalState.selectedSubjects.find((x) => x.id === id);

      if (subject != undefined) {
        const newSubjects = modalState.selectedSubjects.filter(
          (classe) => classe.id !== id
        );

        modalState.selectedSubjects = newSubjects;
      }

      this.setState(modalState);
    }
  }

  setModalAuthenticateMessageAndOpen = (message) => {
    this.setState({ modalAuthenticateMessage: message }, () =>
      this.toggleModalAuthenticate().then(() => this.setToken(""))
    );
  };

  toggleModalAuthenticate = () => {
    return new Promise((resolve, reject) => {
      this.setState(
        {
          modalAuthenticateIsOpen: !this.state.modalAuthenticateIsOpen,
        },
        resolve(true)
      );
    });
  };

  handleAuthenticationInput = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    let modalState = this.state;
    modalState[name] = value;

    this.setState(modalState);
  };

  save() {
    let studentList = [];
    this.state.selectedStudentIds.map((id, index) =>
      studentList.push({
        Id: id,
      })
    );

    let studentClasses = [];
    this.state.selectedSubjects.map((classe, index) =>
      studentClasses.push({
        Id: classe.id,
        Classe: { Id: classe.value },
        StartDate: classe.startDate,
      })
    );

    let bulkEnrollParam = {
      StudentList: studentList,
      StudentClassList: studentClasses,
    };

    axios.post("students/bulkenroll", bulkEnrollParam).then((response) => {
      if (
        response.data.success === true &&
        response.data.needAuthorization === true
      ) {
        let message = response.data.messages
          .map((message) => message)
          .join("\n");

        this.setModalAuthenticateMessageAndOpen(message);
      } else {
        this.props.toggle().then(() => {
          this.setToken("").then(() => {
            this.resetModal().then(() => {
              if (
                this.props.callback &&
                typeof this.props.callback === "function"
              ) {
                this.props.callback();
              }
            });
          });
        });
      }
    });
  }

  setToken = (token) => {
    return new Promise((resolve, reject) => {
      authService.setAuth(token);
      resolve(true);
    });
  };

  render() {
    let selectedSubjects = this.state.selectedSubjects.map((classe) => {
      return (
        <tr key={classe.id}>
          <td>{classe.label}</td>
          <td>{classe.teacherName}</td>

          <td>{classe.campusName}</td>
          <td>{classe.semesterName}</td>
          <td>{dateFns.format(classe.startDate, "MM/dd/yyyy")}</td>

          <td>
            <Button
              color="danger"
              size="sm"
              className="mr-2"
              onClick={this.classDelete.bind(this, classe.id)}
            >
              Delete
            </Button>
          </td>
        </tr>
      );
    });

    let students = this.state.students.map((student) => {
      return (
        <tr
          key={student.id}
          onClick={this.setStudentId.bind(this, student.id)}
          style={{ cursor: "pointer" }}
        >
          <td className="text-center customCheckbox">
            <CustomInput
              onClick
              type="checkbox"
              inline
              checked={
                this.state.selectedStudentIds.find((x) => x === student.id) ||
                false
              }
              style={{ cursor: "pointer" }}
            />
          </td>

          <td>{student.name}</td>
        </tr>
      );
    });

    return (
      <>
        <div className="content">
          <Row>
            <Col md="12">
              <Modal
                isOpen={this.props.isOpen}
                toggle={this.props.toggle.bind(this)}
                centered={true}
                autoFocus={true}
                size={"lg"}
                backdrop="static"
                unmountOnClose={true}
                onOpened={this.onOpened.bind(this)}
                onClosed={this.onCloseModal}
                scrollable
                style={{
                  maxWidth: "1600px",
                  width: "80%",
                }}
              >
                <ModalHeader toggle={this.props.toggle.bind(this)}>
                  Enroll
                </ModalHeader>
                <ModalBody>
                  <Row form>
                    <Col md={12}>
                      <Card>
                        <CardHeader>
                          <CardTitle tag="h4">Subjects</CardTitle>
                        </CardHeader>
                        <CardBody>
                          <Row form>
                            <Col md={2}>
                              <FormGroup>
                                <Label for="studentClass">Semester</Label>
                                <Select
                                  value={
                                    this.state.selectedSemesterId &&
                                    this.state.semesters.find(
                                      (x) =>
                                        x.value ===
                                        this.state.selectedSemesterId
                                    )
                                  }
                                  onChange={this.handleSemesterEnrollChange}
                                  options={this.state.semesters}
                                />
                              </FormGroup>
                            </Col>

                            <Col md={2}>
                              <FormGroup>
                                <Label for="studentClass">Level</Label>
                                <Select
                                  value={
                                    this.state.selectedCourseId &&
                                    this.state.courses.find(
                                      (x) =>
                                        x.value === this.state.selectedCourseId
                                    )
                                  }
                                  onChange={this.handleCourseChange}
                                  options={this.state.courses}
                                />
                              </FormGroup>
                            </Col>

                            <Col md={2}>
                              <FormGroup>
                                <Label for="studentClass">Period</Label>
                                <Select
                                  isDisabled={
                                    this.state.selectedCourseId === "" ||
                                    this.state.selectedSemesterId === ""
                                  }
                                  value={
                                    this.state.selectedPeriod &&
                                    this.periodOptions.find(
                                      (x) =>
                                        x.value === this.state.selectedPeriod
                                    )
                                  }
                                  onChange={this.handlePeriodChange}
                                  options={this.periodOptions}
                                />
                              </FormGroup>
                            </Col>

                            <Col md={2}>
                              <FormGroup>
                                <Label for="studentClass">Subject</Label>
                                <Select
                                  isDisabled={
                                    this.state.selectedCourseId === "" ||
                                    this.state.selectedPeriod === "" ||
                                    this.state.selectedSemesterId === ""
                                  }
                                  value={
                                    this.state.selectedClassId &&
                                    this.state.classes.find(
                                      (x) =>
                                        x.value === this.state.selectedClassId
                                    )
                                  }
                                  onChange={this.handleClassChange}
                                  options={this.state.classes}
                                />
                              </FormGroup>
                            </Col>

                            <Col md={2}>
                              <FormGroup>
                                <Label for="studentClass">
                                  First day of class
                                </Label>

                                <DatePicker
                                  selected={this.state.firstDayOfClass}
                                  onChange={this.handleFirstDayOfClassChange.bind(
                                    this
                                  )}
                                  name="FirstDayOfClass"
                                  id="FirstDayOfClass"
                                  dateFormat="MM/dd/yyyy"
                                />
                              </FormGroup>
                            </Col>

                            <Col md={2}>
                              <FormGroup>
                                <Label>&nbsp;</Label>
                                <Button
                                  color="primary"
                                  style={{ margin: "0" }}
                                  onClick={this.addSubject.bind(this)}
                                  disabled={
                                    this.state.selectedClassId === "" ||
                                    !dateFns.isValid(this.state.firstDayOfClass)
                                  }
                                >
                                  Add
                                </Button>
                              </FormGroup>
                            </Col>
                          </Row>

                          {selectedSubjects.length > 0 && (
                            <Row form>
                              <Table className="table table-striped">
                                <thead>
                                  <tr>
                                    <th>Subject</th>
                                    <th>Teacher</th>
                                    <th>Campus</th>
                                    <th>Semester</th>
                                    <th>Start Date</th>
                                    <th>Actions</th>
                                  </tr>
                                </thead>
                                <tbody>{selectedSubjects}</tbody>
                              </Table>
                            </Row>
                          )}
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>

                  <Row form>
                    <Col md={12}>
                      <Card>
                        <CardHeader>
                          <CardTitle tag="h5">Students</CardTitle>
                        </CardHeader>
                        <CardBody>
                          <Row form>
                            <Table>
                              <thead>
                                <tr>
                                  <th>Name</th>
                                </tr>
                              </thead>

                              <tbody>{students}</tbody>
                            </Table>
                          </Row>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </ModalBody>
                <ModalFooter>
                  <h6
                    style={{
                      marginLeft: "5px",
                      marginRight: "auto",
                      marginTop: "10px",
                      textTransform: "inherit",
                    }}
                  >
                    <strong className="text-secondary">
                      {this.state.selectedStudentIds.length}
                    </strong>{" "}
                    {"Selected"}
                  </h6>
                  <Button
                    color="primary"
                    onClick={this.save.bind(this)}
                    disabled={
                      this.state.selectedStudentIds.length === 0 ||
                      this.state.selectedSubjects.length === 0
                    }
                  >
                    Enroll
                  </Button>

                  <Button
                    color="secondary"
                    onClick={this.props.toggle.bind(this)}
                  >
                    Cancel
                  </Button>

                  <AuthenticateModal
                    isOpen={this.state.modalAuthenticateIsOpen}
                    toggle={this.toggleModalAuthenticate.bind(this)}
                    message={this.state.modalAuthenticateMessage}
                    note={this.state.modalAuthenticateNote}
                    callback={this.save.bind(this)}
                    onChange={this.handleAuthenticationInput}
                  />
                </ModalFooter>
              </Modal>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

export default StudentEnroll;
