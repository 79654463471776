/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import {
  Card,
  CardBody,
  Table,
  Row,
  Col,
  CardHeader,
  FormGroup,
  Label,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Badge,
  UncontrolledTooltip,
} from "reactstrap";
import axios from "axios";
import * as dateFns from "date-fns";
import Pagination from "components/Pagination/Pagination.js";
import DatePicker from "react-datepicker";
import SearchInput from "components/Search/Search.input.component";
import ApplicationModal from "../Application/Application.Modal.Component";
import { Multiselect } from "multiselect-react-dropdown";
import Can from "components/Can/Can";
import ApplicationOwnerModal from "../Application/Application.Owner.Modal.Component";
import Select from "react-select";
import jsPDF from "jspdf";
import { swalmessageService } from "services/Message.service.js";

class ApplicationReport extends React.Component {
  constructor(props) {
    super(props);
    this.state = this.getMainState();
  }

  getMainState = () => {
    return {
      applications: [],
      StartDateSearch: dateFns.addMonths(new Date(), -6),
      EndDateSearch: dateFns.endOfWeek(new Date()),
      applyId: "",
      applyName: "",
      totalResults: 0,
      currentPage: 1,
      itemsPerPage: 10,
      modalIsOpen: false,
      logModalIsOpen: false,
      ownerModalIsOpen: false,
      review: "",
      nameSearch: "",
      codeSearch: "",
      partnerCodeSearch: "",
      emailSearch: "",
      StatusTypeList: [],
      selectedStatusTypes: [],
      ownerId: "",
      semesterId: "",
      campusId: "all",
      visaStatusId: "all",
      typeId: "all",
      ownerList: [],
      semesterList: [],
      convertedStatus: "A",
    };
  };

  toggleModal = () => {
    return new Promise((resolve, reject) => {
      this.setState({ modalIsOpen: !this.state.modalIsOpen }, resolve(true));
    });
  };

  toggleLogModal = () => {
    return new Promise((resolve, reject) => {
      this.setState(
        { logModalIsOpen: !this.state.logModalIsOpen },
        resolve(true)
      );
    });
  };

  toggleOwnerModal = () => {
    return new Promise((resolve, reject) => {
      this.setState(
        { ownerModalIsOpen: !this.state.ownerModalIsOpen },
        resolve(true)
      );
    });
  };

  onCloseModal = () => {
    this.resetModal();
  };

  resetModal = () => {
    return new Promise((resolve, reject) => {
      this.setState({ review: "", applyId: "", applyName: "" }, resolve(true));
    });
  };

  setApplyIdAndToggle = (id, name) => {
    return new Promise((resolve, reject) => {
      this.setState({ applyId: id, applyName: name }, () =>
        this.toggleModal().then(() => this.openApplication())
      );
    });
  };

  setApplyIdAndToggleLog = (id, name) => {
    return new Promise((resolve, reject) => {
      this.setState({ applyId: id, applyName: name }, () =>
        this.toggleLogModal()
      );
    });
  };

  setApplyIdAndToggleOwnerModal = (id, name) => {
    return new Promise((resolve, reject) => {
      this.setState({ applyId: id, applyName: name }, () =>
        this.toggleOwnerModal()
      );
    });
  };

  handleSearchInput = (name, e) => {
    const value = e.target.value;

    this.setState({ [name]: value, currentPage: 1 }, () => {
      this._refreshApplications();
    });
  };

  renderStatusButton = (application) => {
    let color = "";

    switch (application.statusOrder) {
      case 0:
        color = "#f9a02b";
        break;
      case 1:
        color = "#5b5b5b";
        break;
      case 2:
        color = "#10b8f9";
        break;
      case 3:
        color = "#dc3545";
        break;
      case 4:
        color = "#6771f9";
        break;
      case 5:
        color = "#7ac947";
        break;
      default:
        break;
    }

    return (
      <Badge style={{ fontSize: "small", backgroundColor: color }}>
        {application.statusTypeName}
      </Badge>
    );
  };

  renderVisaStatusButton = (visaStatus) => {
    let color = "";

    switch (visaStatus) {
      case "Approved":
        color = "#7ac947";
        break;
      case "Denied":
        color = "#dc3545";
        break;
      case "Dropout":
        color = "#fab526";
        break;
      case "In Process":
        color = "#10b8f9";
        break;

      default:
        break;
    }

    return (
      <Badge style={{ fontSize: "small", backgroundColor: color }}>
        {visaStatus}
      </Badge>
    );
  };

  render() {
    let applications = this.state.applications.map((application, index) => {
      let transactions = application.transactions.map((transaction, index2) => {
        let color =
          transaction.amount === 20
            ? "#ff4a4a"
            : transaction.amount === 100
            ? "#f4d573"
            : transaction.amount === 720
            ? "#f9a02b"
            : transaction.amount === 1080
            ? "#b455e5"
            : "#6771f9";

        return (
          <React.Fragment key={index2}>
            <Badge
              className="badgeTransaction"
              style={{ fontSize: "small", backgroundColor: color }}
            >
              {transaction.amount ? "$" + transaction.amount : null}
            </Badge>
          </React.Fragment>
        );
      });
      return (
        <tr key={index}>
          <td>
            <a
              onClick={this.setApplyIdAndToggle.bind(
                this,
                application.id,
                application.personalFullName
              )}
              href="#"
            >
              {application.personalFullName}
            </a>
          </td>
          <td>{transactions}</td>
          <td>{application.applicationChoice}</td>
          <td>{application.partnerCode}</td>
          <td>{application.semester}</td>
          <td>{application.campus}</td>
          <td>{application.course}</td>
          <td>{dateFns.format(application.createdDate, "MM/dd/yyyy")}</td>
          <td>{this.renderStatusButton(application)}</td>
          <td>{this.renderVisaStatusButton(application.visaStatus)}</td>
          <Can
            feature="Application_View_Action_Columns"
            yes={() => (
              <React.Fragment>
                <td className="hideColumn2">{application.ownerName}</td>{" "}
                <td>
                  <Button
                    color="success"
                    size="sm"
                    className="mr-2 buttonApplicationTable"
                    onClick={this.setApplyIdAndToggleOwnerModal.bind(
                      this,
                      application.id,
                      application.personalFullName
                    )}
                    disabled={application.ownerName}
                    style={{ opacity: application.ownerName ? "0.2" : "1" }}
                  >
                    Assign
                  </Button>

                  <Button
                    color="warning"
                    size="sm"
                    className="mr-2 buttonApplicationTable"
                    onClick={this.setApplyIdAndToggleLog.bind(
                      this,
                      application.id,
                      application.personalFullName
                    )}
                    disabled={!application.ownerName}
                    style={{ opacity: !application.ownerName ? "0.2" : "1" }}
                  >
                    Log
                  </Button>

                  <Button
                    color="info"
                    size="sm"
                    className="mr-2 buttonApplicationTable"
                    onClick={this.downloadAttachments.bind(
                      this,
                      application.id
                    )}
                  >
                    Files
                  </Button>
                </td>
              </React.Fragment>
            )}
            no={() => (
              <td>
                <Button
                  color="warning"
                  size="sm"
                  className="mr-2"
                  onClick={this.getLastLog.bind(this, application.id)}
                >
                  Log
                </Button>
              </td>
            )}
          ></Can>
          <Can
            feature="Application_Convert_To_Student"
            yes={() => (
              <td>
                {application.convertedToStudentDate && (
                  <Button
                    color="danger"
                    size="sm"
                    className="mr-2 buttonApplicationTable"
                    onClick={this._convertApplicationToStudent.bind(
                      this,
                      application.id
                    )}
                    disabled={true}
                  >
                    Converted
                  </Button>
                )}
                {application.convertedToStudentDate === undefined && (
                  <Button
                    color="success"
                    size="sm"
                    className="mr-2 buttonApplicationTable"
                    onClick={this._convertApplicationToStudent.bind(
                      this,
                      application.id
                    )}
                  >
                    Convert
                  </Button>
                )}
              </td>
            )}
          ></Can>
        </tr>
      );
    });

    return (
      <>
        <div className="content">
          <Row>
            <Col md="12">
              <Card>
                <CardHeader>
                  <Row form>
                    <FormGroup style={{ marginLeft: "5px" }}>
                      <Label>Start Date</Label>

                      <DatePicker
                        selected={this.state.StartDateSearch}
                        onChange={this.handleSearchDateInput.bind(
                          this,
                          "StartDateSearch"
                        )}
                        name="StartDateSearch"
                        dateFormat="MM/dd/yyyy"
                      />
                    </FormGroup>

                    <FormGroup style={{ marginLeft: "5px" }}>
                      <Label>End Date</Label>
                      <DatePicker
                        selected={this.state.EndDateSearch}
                        onChange={this.handleSearchDateInput.bind(
                          this,
                          "EndDateSearch"
                        )}
                        name="EndDateSearch"
                        dateFormat="MM/dd/yyyy"
                      />
                    </FormGroup>

                    <SearchInput
                      searchInput={this.state.nameSearch}
                      placeholder="Search by name"
                      handleSearchInput={this.handleSearchInput.bind(
                        this,
                        "nameSearch"
                      )}
                    />

                    <SearchInput
                      searchInput={this.state.codeSearch}
                      placeholder="Search by code"
                      handleSearchInput={this.handleSearchInput.bind(
                        this,
                        "codeSearch"
                      )}
                    />

                    <SearchInput
                      searchInput={this.state.partnerCodeSearch}
                      placeholder="Search by partner code"
                      handleSearchInput={this.handleSearchInput.bind(
                        this,
                        "partnerCodeSearch"
                      )}
                    />

                    <SearchInput
                      searchInput={this.state.emailSearch}
                      placeholder="Search by e-mail"
                      handleSearchInput={this.handleSearchInput.bind(
                        this,
                        "emailSearch"
                      )}
                    />

                    <Col md={2}>
                      <FormGroup>
                        <Label for="studentClass">Type</Label>
                        <Select
                          className={"form-group"}
                          value={
                            this.state.typeId &&
                            this.typeOptions.find(
                              (x) => x.value === this.state.typeId
                            )
                          }
                          onChange={this.handleTypeChange}
                          options={this.typeOptions}
                        />
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row form>
                    <Col md={2}>
                      <FormGroup>
                        <Label for="studentClass">Semester</Label>
                        <Select
                          className={"form-group"}
                          value={
                            this.state.semesterId &&
                            this.state.semesterList.find(
                              (x) => x.value === this.state.semesterId
                            )
                          }
                          onChange={this.handleSemesterChange}
                          options={this.state.semesterList}
                        />
                      </FormGroup>
                    </Col>

                    <Col md={2}>
                      <FormGroup>
                        <Label for="studentClass">Campus</Label>
                        <Select
                          className={"form-group"}
                          value={
                            this.state.campusId &&
                            this.campusOptions.find(
                              (x) => x.value === this.state.campusId
                            )
                          }
                          onChange={this.handleCampusChange}
                          options={this.campusOptions}
                        />
                      </FormGroup>
                    </Col>

                    <Can
                      feature="Application_View_Action_Columns"
                      yes={() => (
                        <Col md={2}>
                          <FormGroup>
                            <Label for="studentClass">Owner</Label>
                            <Select
                              className={"form-group"}
                              value={
                                this.state.ownerId &&
                                this.state.ownerList.find(
                                  (x) => x.value === this.state.ownerId
                                )
                              }
                              onChange={this.handleOwnerChange}
                              options={this.state.ownerList}
                            />
                          </FormGroup>
                        </Col>
                      )}
                    ></Can>

                    <Col md={2}>
                      <FormGroup>
                        <Label for="studentClass">Converted status</Label>
                        <Select
                          className={"form-group"}
                          value={
                            this.state.convertedStatus &&
                            this.convertedStatusOptions.find(
                              (x) => x.value === this.state.convertedStatus
                            )
                          }
                          onChange={this.handleConvertedStatusChange}
                          options={this.convertedStatusOptions}
                        />
                      </FormGroup>
                    </Col>

                    <Col md={2}>
                      <FormGroup>
                        <Label for="studentClass">Visa</Label>
                        <Select
                          className={"form-group"}
                          value={
                            this.state.visaStatusId &&
                            this.visaStatusOptions.find(
                              (x) => x.value === this.state.visaStatusId
                            )
                          }
                          onChange={this.handleVisaStatusChange}
                          options={this.visaStatusOptions}
                        />
                      </FormGroup>
                    </Col>

                    <Col md={2}>
                      <Label>Status</Label>
                      <Multiselect
                        options={this.state.StatusTypeList}
                        onSelect={this.onSelect.bind(this)}
                        onRemove={this.onRemove.bind(this)}
                        displayValue="name"
                      />
                    </Col>
                  </Row>
                </CardHeader>

                <CardBody>
                  <Table>
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>Transactions</th>
                        <th>Type</th>
                        <th>Partner</th>
                        <th>Semester</th>
                        <th>Campus</th>
                        <th>Level</th>
                        <th>Date</th>
                        <th>Status</th>
                        <th>Visa</th>

                        <Can
                          feature="Application_View_Action_Columns"
                          yes={() => (
                            <React.Fragment>
                              <th className="hideColumn2">Owner</th>
                              <th></th>
                            </React.Fragment>
                          )}
                          no={() => (
                            <React.Fragment>
                              <th></th>
                            </React.Fragment>
                          )}
                        ></Can>

                        <Can
                          feature="Application_Convert_To_Student"
                          yes={() => <th>Student</th>}
                        ></Can>
                      </tr>
                    </thead>

                    <tbody>{applications}</tbody>
                  </Table>

                  <Pagination
                    activePage={this.state.currentPage}
                    totalItemsCount={this.state.totalResults}
                    onChange={this._refreshApplications.bind(this)}
                    csv={this.DownloadCsv.bind(this)}
                    //pdf={this.DownloadComissionReportCsv.bind(this)}
                    buttonName="Export to CSV"
                    //pdfButtonName="Comission Report"
                  />

                  <Modal
                    isOpen={this.state.modalIsOpen}
                    toggle={this.toggleModal.bind(this)}
                    centered={true}
                    autoFocus={true}
                    backdrop="static"
                    onClosed={this.onCloseModal}
                    scrollable={true}
                    size={"lg"}
                  >
                    <ModalHeader toggle={this.toggleModal.bind(this)}>
                      Application - {this.state.applyName}
                    </ModalHeader>
                    <ModalBody>
                      <Row form>
                        <Col md={12}>
                          <FormGroup>
                            <p style={{ whiteSpace: "pre-line" }}>
                              {this.state.review}
                            </p>
                          </FormGroup>
                        </Col>
                      </Row>
                    </ModalBody>
                    <ModalFooter>
                      <Button
                        color="primary"
                        onClick={this.print.bind(this)}
                        disabled={this.props.buttonPrintDisabled}
                      >
                        Print
                      </Button>

                      <Button
                        color="secondary"
                        onClick={this.toggleModal.bind(this)}
                      >
                        Close
                      </Button>
                    </ModalFooter>
                  </Modal>

                  <ApplicationModal
                    isOpen={this.state.logModalIsOpen}
                    toggle={this.toggleLogModal.bind(this)}
                    id={this.state.applyId}
                    callback={this._refreshApplications.bind(this)}
                    applyName={this.state.applyName}
                    tables={this.props.tables}
                  />

                  <ApplicationOwnerModal
                    isOpen={this.state.ownerModalIsOpen}
                    toggle={this.toggleOwnerModal}
                    callback={this._refreshApplications.bind(this)}
                    applyId={this.state.applyId}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </>
    );
  }

  componentDidMount() {
    this._refreshApplications().then(() => {
      this.refreshOwnerList().then(() => {
        this.refreshSemesters().then(() => {
          this.refreshStatusTypeList();
        });
      });
    });
  }

  onSelect(selectedList, selectedItem) {
    let mainState = this.state;
    mainState.selectedStatusTypes.push(selectedItem);
    this.setState(mainState, () => this._refreshApplications());
  }

  onRemove(selectedList, removedItem) {
    let mainState = this.state;
    let newSelectedStatusTypes = mainState.selectedStatusTypes.filter(
      (x) => x.id !== removedItem.id
    );
    mainState.selectedStatusTypes = newSelectedStatusTypes;
    this.setState(mainState, () => this._refreshApplications());
  }

  refreshStatusTypeList = () => {
    return new Promise((resolve, reject) => {
      axios.get("applystatustypes").then((response) => {
        let mainState = this.state;

        mainState.StatusTypeList = [];

        response.data.data.map((statusType, index) =>
          mainState.StatusTypeList.push({
            id: statusType.id,
            name: statusType.name,
          })
        );

        this.setState(mainState, resolve(true));
      });
    });
  };

  handleSearchDateInput = (name, date) => {
    if (date === null) date = new Date();

    let mainState = this.state;

    mainState[name] = date;
    mainState.currentPage = 1;

    this.setState(mainState, () => {
      this._refreshApplications();
    });
  };

  handleConvertedStatusChange = (selectedOption) => {
    let mainState = this.state;

    mainState.convertedStatus = selectedOption.value;
    mainState.currentPage = 1;

    this.setState({ mainState: mainState }, () => {
      this._refreshApplications();
    });
  };

  openApplication() {
    axios.get("apply/getReview?id=" + this.state.applyId).then((response) => {
      let mainState = this.state;
      mainState.review = response.data.data;
      this.setState(mainState);
    });
  }

  getLastLog(id) {
    axios.get("apply/getlastlog?id=" + id).then((response) => {
      swalmessageService.sendMessage(response.data.data);
    });
  }

  downloadAttachments(id) {
    axios.get("apply/byid?id=" + id).then((response) => {
      let apply = response.data.data;

      if (apply) {
        if (apply.documentVisa)
          this.downloadDocumentFile(
            apply.documentVisa.id,
            apply.documentVisa.fileName
          );

        if (apply.documentPassport)
          this.downloadDocumentFile(
            apply.documentPassport.id,
            apply.documentPassport.fileName
          );

        if (apply.documentI20)
          this.downloadDocumentFile(
            apply.documentI20.id,
            apply.documentI20.fileName
          );

        if (apply.documentTransfer)
          this.downloadDocumentFile(
            apply.documentTransfer.id,
            apply.documentTransfer.fileName
          );

        if (apply.documentBank)
          this.downloadDocumentFile(
            apply.documentBank.id,
            apply.documentBank.fileName
          );

        if (apply.documentSignature)
          this.downloadDocumentFile(
            apply.documentSignature.id,
            apply.documentSignature.fileName
          );

        if (apply.documentAffidavit)
          this.downloadDocumentFile(
            apply.documentAffidavit.id,
            apply.documentAffidavit.fileName
          );

        if (apply.dependentList) {
          apply.dependentList.forEach((dependent) => {
            if (dependent.document && dependent.document.id) {
              this.downloadDocumentFile(
                dependent.document.id,
                dependent.document.fileName
              );
            }
          });
        }
      }
    });
  }

  downloadDocumentFile(id, fileName) {
    axios
      .get("documents/getFileByDate?id=" + id, {
        responseType: "blob",
      })
      .then((response) => {
        const url = window.URL.createObjectURL(
          new Blob([response.data], { type: response.data.type })
        );
        const link = document.createElement("a");
        link.href = url;

        link.setAttribute("download", fileName);
        document.body.appendChild(link);

        link.click();
      });
  }

  _refreshApplications(pageNumber) {
    let page = pageNumber || 1;
    let itemsPerPage = this.state.itemsPerPage;
    let startDate = dateFns.format(this.state.StartDateSearch, "yyyy-MM-dd");
    let endDate = dateFns.format(this.state.EndDateSearch, "yyyy-MM-dd");
    let ownerId = this.state.ownerId;
    let campusId = this.state.campusId;
    let visaStatusId = this.state.visaStatusId;
    let typeId = this.state.typeId;
    let semesterId = this.state.semesterId;

    if (ownerId === "all") ownerId = "";
    if (campusId === "all") campusId = "";
    if (visaStatusId === "all") visaStatusId = "";
    if (typeId === "all") typeId = "";
    if (semesterId === "all") semesterId = "";

    let statusTypeList = [];
    this.state.selectedStatusTypes.map((statusType, index) =>
      statusTypeList.push({
        Id: statusType.id,
      })
    );

    let getAllParam = {
      page: page,
      pageSize: itemsPerPage,
      startDate: startDate,
      endDate: endDate,
      nameSearch: this.state.nameSearch,
      codeSearch: this.state.codeSearch,
      partnerCodeSearch: this.state.partnerCodeSearch,
      emailSearch: this.state.emailSearch,
      statusTypeList: statusTypeList,
      ownerId: ownerId,
      campus: campusId,
      visaStatus: visaStatusId,
      type: typeId,
      semester: semesterId,
      convertedStatus: this.state.convertedStatus,
    };

    return new Promise((resolve, reject) => {
      axios.post("apply/getall?", getAllParam).then((response) => {
        let mainState = this.state;

        mainState.applications = [];
        response.data.data.items.map((application, index) => {
          let transactions = application.applyTransactionList
            .sort((a, b) => a.amount - b.amount)
            .map((transaction, index) => {
              return {
                id: transaction && transaction.id,
                amount: transaction && transaction.amount,
              };
            });
          mainState.applications.push({
            id: application.id,
            code: application.code,
            amount: application.amount,
            personalFullName: application.personalFullName,
            applicationChoice:
              application.applicationChoice === "Initial Attendance"
                ? "In.At."
                : application.applicationChoice === "Change of Status"
                ? "Ch.St."
                : application.applicationChoice === "Transfer In"
                ? "Tr.In"
                : application.applicationChoice,
            createdDate: dateFns.parseISO(application.createdDate),
            convertedToStudentDate:
              application.convertedToStudentDate &&
              dateFns.parseISO(application.convertedToStudentDate),
            partnerCode: application.partnerCode,
            semester: application.semester
              .replace("Winter", "W.")
              .replace("Summer", "S.")
              .replace("Fall", "F."),
            campus:
              application.campus === "Salt Lake City"
                ? "SLC"
                : application.campus,
            dependents: application.dependentList,
            documentVisa: application.documentVisa,
            documentPassport: application.documentPassport,
            documentI20: application.documentI20,
            documentTransfer: application.documentTransfer,
            documentBank: application.documentBank,
            documentSignature: application.documentSignature,
            documentAffidavit: application.documentAffidavit,
            ownerName:
              application.applyLogList[0].owner &&
              application.applyLogList[0].owner.name.slice(
                0,
                application.applyLogList[0].owner.name.lastIndexOf("@")
              ),
            statusTypeName:
              application.applyLogList[0].status &&
              application.applyLogList[0].status.name,

            statusOrder:
              application.applyLogList[0].status &&
              application.applyLogList[0].status.order,

            transactions: transactions,

            visaStatus: application.visaStatus,
            course: application.course && application.course.name,
          });
        });

        mainState.currentPage = pageNumber;
        mainState.totalResults = response.data.data.totalCount;

        this.setState(mainState, resolve(true));
      });
    });
  }

  _convertApplicationToStudent(id) {
    return new Promise((resolve, reject) => {
      axios
        .post("apply/convertApplicationToStudent", { Id: id })
        .then((response) => {
          this._refreshApplications();
        });
    });
  }

  DownloadCsv() {
    let startDate = dateFns.format(this.state.StartDateSearch, "yyyy-MM-dd");
    let endDate = dateFns.format(this.state.EndDateSearch, "yyyy-MM-dd");
    let ownerId = this.state.ownerId;
    let campusId = this.state.campusId;
    let visaStatusId = this.state.visaStatusId;
    let typeId = this.state.typeId;
    let semesterId = this.state.semesterId;

    if (ownerId === "all") ownerId = "";
    if (campusId === "all") campusId = "";
    if (visaStatusId === "all") visaStatusId = "";
    if (typeId === "all") typeId = "";
    if (semesterId === "all") semesterId = "";

    let statusTypeList = [];
    this.state.selectedStatusTypes.map((statusType, index) =>
      statusTypeList.push({
        Id: statusType.id,
      })
    );

    let getAllParam = {
      startDate: startDate,
      endDate: endDate,
      nameSearch: this.state.nameSearch,
      codeSearch: this.state.codeSearch,
      partnerCodeSearch: this.state.partnerCodeSearch,
      emailSearch: this.state.emailSearch,
      statusTypeList: statusTypeList,
      ownerId: ownerId,
      campus: campusId,
      visaStatus: visaStatusId,
      type: typeId,
      semester: semesterId,
      convertedStatus: this.state.convertedStatus,
    };

    axios
      .post("apply/reportcsv", getAllParam, {
        responseType: "blob",
      })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "Applications.csv");
        document.body.appendChild(link);
        link.click();
      });
  }

  DownloadComissionReportCsv() {
    let startDate = dateFns.format(this.state.StartDateSearch, "yyyy-MM-dd");
    let endDate = dateFns.format(this.state.EndDateSearch, "yyyy-MM-dd");

    let statusTypeList = [];
    this.state.selectedStatusTypes.map((statusType, index) =>
      statusTypeList.push({
        Id: statusType.id,
      })
    );

    let getAllParam = {
      startDate: startDate,
      endDate: endDate,
      nameSearch: this.state.nameSearch,
      codeSearch: this.state.codeSearch,
      partnerCodeSearch: this.state.partnerCodeSearch,
      emailSearch: this.state.emailSearch,
      statusTypeList: statusTypeList,
    };

    axios
      .post("apply/comissionreport", getAllParam, {
        responseType: "blob",
      })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "ComissionReport.csv");
        document.body.appendChild(link);
        link.click();
      });
  }

  handleOwnerChange = (selectedOption) => {
    let mainState = this.state;

    mainState.ownerId = selectedOption.value;
    mainState.currentPage = 1;

    this.setState(mainState, () => {
      this._refreshApplications();
    });
  };

  handleSemesterChange = (selectedOption) => {
    let mainState = this.state;

    mainState.semesterId = selectedOption.value;
    mainState.currentPage = 1;

    this.setState(mainState, () => {
      this._refreshApplications();
    });
  };

  handleCampusChange = (selectedOption) => {
    let mainState = this.state;

    mainState.campusId = selectedOption.value;
    mainState.currentPage = 1;

    this.setState(mainState, () => {
      this._refreshApplications();
    });
  };

  handleVisaStatusChange = (selectedOption) => {
    let mainState = this.state;

    mainState.visaStatusId = selectedOption.value;
    mainState.currentPage = 1;

    this.setState(mainState, () => {
      this._refreshApplications();
    });
  };

  handleTypeChange = (selectedOption) => {
    let mainState = this.state;

    mainState.typeId = selectedOption.value;
    mainState.currentPage = 1;

    this.setState(mainState, () => {
      this._refreshApplications();
    });
  };

  refreshOwnerList = () => {
    return new Promise((resolve, reject) => {
      axios.get("users/getAllowedApplicationOwners").then((response) => {
        let mainState = this.state;

        mainState.ownerList = [];

        mainState.ownerList.push({
          value: "all",
          label: "All owners",
        });

        mainState.ownerId = "all";

        response.data.data.map((user, index) =>
          mainState.ownerList.push({
            value: user.id,
            label: user.fullName,
          })
        );

        this.setState(mainState, resolve(true));
      });
    });
  };

  refreshSemesters = () => {
    return new Promise((resolve, reject) => {
      let modalState = this.state;

      modalState.semesterList.push({
        value: "all",
        label: "All semesters",
      });

      this.props.tables.semesters.map((semester, index) =>
        modalState.semesterList.push({
          value: semester.name,
          label: semester.name,
        })
      );

      modalState.semesterId = "all";

      this.setState(modalState, resolve(true));
    });
  };

  print = () => {
    const doc = new jsPDF("p", "mm", "letter");

    let pageDetails = [];
    let review = this.state.review;

    let row1 = [
      {
        content: review,
        styles: {
          valign: "middle",
          halign: "left",
          fontStyle: "normal",
        },
      },
    ];

    pageDetails.push(row1);

    doc.autoTable({
      body: pageDetails,
      didDrawPage: function (data) {
        doc.setFontSize(10);
      },
      margin: {
        top: 10,
      },

      styles: {
        fontSize: 10,
      },
      theme: "plain",
    });

    doc.output("dataurlnewwindow");
  };

  campusOptions = [
    { value: "all", label: "All campuses" },
    { value: "Salt Lake City", label: "Salt Lake City" },
    { value: "Orem", label: "Orem" },
  ];

  convertedStatusOptions = [
    { value: "A", label: "All" },
    { value: "C", label: "Converted only" },
    { value: "P", label: "Pending only" },
  ];

  visaStatusOptions = [
    { value: "all", label: "All" },
    { value: "Approved", label: "Approved" },
    { value: "Denied", label: "Denied" },
    { value: "Dropout", label: "Dropout" },
    { value: "In Process", label: "In Process" },
  ];

  typeOptions = [
    { value: "all", label: "All" },
    { value: "Change of Status", label: "Change of Status" },
    { value: "Initial Attendance", label: "Initial Attendance" },
    { value: "NON-F1", label: "NON-F1" },
    { value: "Swift", label: "Swift" },
    { value: "Transfer In", label: "Transfer In" },
  ];
}

export default ApplicationReport;
