/* eslint-disable no-mixed-operators */
/* eslint-disable array-callback-return */
import React from "react";

import {
  Row,
  Col,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  FormGroup,
  Input,
  Label,
  ModalFooter,
  Card,
  CardHeader,
  CardBody,
  Table,
  CardTitle,
  CustomInput,
} from "reactstrap";
import axios from "axios";
import Select from "react-select";
import * as dateFns from "date-fns";
import confirm from "reactstrap-confirm";
import uuid from "uuid";
import { FinancialContractPdf } from "components/Report/FinancialContractPdf.js";
import { authenticationService } from "services/Authentication.service.js";

class SelfEnrollmentModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = this.getModalState();

    this.timeout = 0;
  }

  getSelfEnrollment = () => {
    const selfEnrollment = {
      Id: "",
      Plan: "",
      FinancialPlan: "",
      Period: "",
      LevelId: "",
      CampusId: "",
      Email: "",
      PhoneNumber: "",
      Street: "",
      Street2: "",
      City: "",
      State: "",
      Zip: "",
      OtherReason: "",
      LevelReason: "",
      StatusTypeId: "",
      Description: "",
      LogList: [],
      LastStatusTypeId: "",
      DocumentSignatureId: "",
      DocumentSignatureFileName: "",
      DocumentInitialsId: "",
      DocumentInitialsFileName: "",
      Semester: "",
      SemesterStartDate: null,
      StudentId: "",
      StudentName: "",
      StudentI20: "",
      StudentVisaTypeName: "",
      StudentBirthdate: "",
      StartedBeforeSeptember: false,
    };

    return selfEnrollment;
  };

  getModalState = () => {
    return {
      Application: this.getSelfEnrollment(),
      StatusTypeList: [],
      UserList: [],
      courses: [],
      campuses: [],
      currentUser: authenticationService.currentUserValue,
    };
  };

  onInputClick = (event) => {
    event.target.value = "";
  };

  render() {
    let logList = this.state.Application.LogList.map((log, index) => {
      return (
        <tr key={index}>
          <td>{log.description}</td>
          <td>{log.statusName}</td>
          <td>{log.userName}</td>
          <td>{dateFns.format(log.createdDate, "MM/dd/yyyy hh:mm:ss aa")}</td>
        </tr>
      );
    });

    let status = this.state.StatusTypeList.find(
      (x) => x.value === this.state.Application.StatusTypeId
    );

    return (
      <>
        <div className="content">
          <Row>
            <Col md="12">
              <Modal
                style={{
                  maxWidth: "1100px",
                  width: "80%",
                }}
                isOpen={this.props.isOpen}
                toggle={this.props.toggle.bind(this)}
                centered={true}
                autoFocus={true}
                size={"lg"}
                backdrop="static"
                onClosed={this.onCloseModal}
                onOpened={this.onOpened.bind(this)}
                scrollable
              >
                <ModalHeader toggle={this.props.toggle.bind(this)}>
                  {"Log - " + this.props.name}
                  <strong>{this.state.Application.StatusTypeName}</strong>
                </ModalHeader>
                <ModalBody
                  style={{ backgroundColor: "#f4f3ef", paddingBottom: "0" }}
                >
                  <Row form>
                    <Col md={12}>
                      <Card>
                        <CardHeader>
                          <CardTitle tag="h5">Details</CardTitle>
                        </CardHeader>
                        <CardBody>
                          <React.Fragment>
                            <Row form>
                              <Col md={3}>
                                <FormGroup>
                                  <Label for="classeName">Status</Label>
                                  <Select
                                    className={`form-group`}
                                    value={this.state.StatusTypeList.find(
                                      (x) =>
                                        x.value ===
                                        this.state.Application.StatusTypeId
                                    )}
                                    options={this.state.StatusTypeList}
                                    onChange={this.handleStatusChange}
                                  />
                                </FormGroup>
                              </Col>
                              <Col md={3}>
                                <FormGroup>
                                  <Label for="classeName">Plan</Label>
                                  <Select
                                    className={`form-group `}
                                    value={
                                      this.state.Application.Plan &&
                                      this.planOptions.find(
                                        (x) =>
                                          x.value ===
                                          this.state.Application.Plan
                                      )
                                    }
                                    onChange={this.handlePlanChange}
                                    options={this.planOptions}
                                  />
                                </FormGroup>
                              </Col>
                            </Row>

                            {this.state.Application.Plan === "S" && (
                              <Row form>
                                <Col md={3}>
                                  <FormGroup>
                                    <Label for="classeCode">Level</Label>
                                    <Select
                                      className={`form-group `}
                                      value={
                                        this.state.Application.LevelId &&
                                        this.state.courses.find(
                                          (x) =>
                                            x.value ===
                                            this.state.Application.LevelId
                                        )
                                      }
                                      onChange={this.handleCourseChange}
                                      options={this.state.courses}
                                    />
                                  </FormGroup>
                                </Col>

                                <Col md={3}>
                                  <FormGroup>
                                    <Label for="classeCode">Campus</Label>
                                    <Select
                                      className={`form-group `}
                                      value={
                                        this.state.Application.CampusId &&
                                        this.state.campuses.find(
                                          (x) =>
                                            x.value ===
                                            this.state.Application.CampusId
                                        )
                                      }
                                      onChange={this.handleCampusChange}
                                      options={this.state.campuses}
                                    />
                                  </FormGroup>
                                </Col>

                                <Col md={3}>
                                  <FormGroup>
                                    <Label for="classeCode">Period</Label>
                                    <Select
                                      className={`form-group `}
                                      value={
                                        this.state.Application.Period &&
                                        this.periodOptions.find(
                                          (x) =>
                                            x.value ===
                                            this.state.Application.Period
                                        )
                                      }
                                      onChange={this.handlePeriodChange}
                                      options={this.periodOptions}
                                    />
                                  </FormGroup>
                                </Col>

                                <Col md={3}>
                                  <FormGroup>
                                    <Label for="classeCode">
                                      Financial Plan
                                    </Label>
                                    <Select
                                      className={`form-group `}
                                      value={
                                        this.state.Application.FinancialPlan &&
                                        this.financialPlanOptions.find(
                                          (x) =>
                                            x.value ===
                                            this.state.Application.FinancialPlan
                                        )
                                      }
                                      onChange={this.handleFinancialPlanChange}
                                      options={this.financialPlanOptions}
                                    />
                                  </FormGroup>
                                </Col>
                              </Row>
                            )}

                            {this.state.Application.OtherReason &&
                              this.state.Application.OtherReason.length > 0 && (
                                <Row form>
                                  <Col md={12}>
                                    <FormGroup>
                                      <Label for="classeCode">Reason</Label>
                                      <Input
                                        className={`form-group`}
                                        type="textarea"
                                        name="Code"
                                        id="classeCode"
                                        value={
                                          this.state.Application.OtherReason
                                        }
                                        disabled={true}
                                      />
                                    </FormGroup>
                                  </Col>
                                </Row>
                              )}

                            {this.state.Application.LevelReason &&
                              this.state.Application.LevelReason.length > 0 && (
                                <Row form>
                                  <Col md={12}>
                                    <FormGroup>
                                      <Label for="classeCode">Reason</Label>
                                      <Input
                                        className={`form-group`}
                                        type="textarea"
                                        name="Code"
                                        id="classeCode"
                                        value={
                                          this.state.Application.LevelReason
                                        }
                                        disabled={true}
                                      />
                                    </FormGroup>
                                  </Col>
                                </Row>
                              )}
                          </React.Fragment>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>

                  <Row form>
                    <Col md={12}>
                      <Card>
                        <CardHeader>
                          <CardTitle tag="h5">Personal Information</CardTitle>
                        </CardHeader>
                        <CardBody>
                          <Row form>
                            <Col md={4}>
                              <FormGroup>
                                <Label for="classeCode">Street Address</Label>
                                <Input
                                  maxLength={"100"}
                                  className={`form-group`}
                                  type="text"
                                  name="Street"
                                  id="classeCode"
                                  value={this.state.Application.Street}
                                  onChange={this.handleUserInput}
                                />
                              </FormGroup>
                            </Col>

                            <Col md={4}>
                              <FormGroup>
                                <Label for="classeCode">Address Line 2</Label>
                                <Input
                                  maxLength={"100"}
                                  className={`form-group`}
                                  type="text"
                                  name="Street2"
                                  id="classeCode"
                                  value={this.state.Application.Street2}
                                  onChange={this.handleUserInput}
                                />
                              </FormGroup>
                            </Col>

                            <Col md={4}>
                              <FormGroup>
                                <Label for="classeCode">City</Label>
                                <Input
                                  maxLength={"45"}
                                  className={`form-group`}
                                  type="text"
                                  name="City"
                                  id="classeCode"
                                  value={this.state.Application.City}
                                  onChange={this.handleUserInput}
                                />
                              </FormGroup>
                            </Col>
                          </Row>

                          <Row form>
                            <Col md={2}>
                              <FormGroup>
                                <Label for="classeCode">State</Label>
                                <Input
                                  maxLength={"100"}
                                  className={`form-group`}
                                  type="text"
                                  name="State"
                                  id="classeCode"
                                  value={this.state.Application.State}
                                  onChange={this.handleUserInput}
                                />
                              </FormGroup>
                            </Col>

                            <Col md={2}>
                              <FormGroup>
                                <Label for="classeCode">Zip Code</Label>
                                <Input
                                  maxLength={"45"}
                                  className={`form-group`}
                                  type="text"
                                  name="Zip"
                                  id="classeCode"
                                  value={this.state.Application.Zip}
                                  onChange={this.handleUserInput}
                                />
                              </FormGroup>
                            </Col>

                            <Col md={4}>
                              <FormGroup>
                                <Label for="classeCode">Email</Label>
                                <Input
                                  maxLength={"100"}
                                  className={`form-group`}
                                  type="text"
                                  name="Email"
                                  id="classeCode"
                                  value={this.state.Application.Email}
                                  onChange={this.handleUserInput}
                                />
                              </FormGroup>
                            </Col>

                            <Col md={4}>
                              <FormGroup>
                                <Label for="classeCode">Phone number</Label>
                                <Input
                                  maxLength={"45"}
                                  className={`form-group`}
                                  type="text"
                                  name="PhoneNumber"
                                  id="classeCode"
                                  value={this.state.Application.PhoneNumber}
                                  onChange={this.handleUserInput}
                                />
                              </FormGroup>
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>

                  <Row form>
                    <Col md={12}>
                      <Card>
                        <CardHeader>
                          <CardTitle tag="h5">New note</CardTitle>
                        </CardHeader>
                        <CardBody>
                          <Row form>
                            <Col md={12}>
                              <FormGroup>
                                <Label for="classeName">Note</Label>
                                <Input
                                  className={`form-group`}
                                  type="textarea"
                                  name="Description"
                                  id="studentNote"
                                  autoComplete="nope"
                                  value={this.state.Application.Description}
                                  onChange={this.handleUserInput}
                                  placeholder={"Type the new note here..."}
                                />
                              </FormGroup>
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>

                  <Row form>
                    <Col md={12}>
                      <Card>
                        <CardHeader>
                          <CardTitle tag="h5">Previous notes</CardTitle>
                        </CardHeader>
                        <CardBody>
                          <Row form>
                            <Table className="table table-striped">
                              <thead style={{ whiteSpace: "unset" }}>
                                <tr>
                                  <th>Note</th>
                                  <th>Status</th>
                                  <th>User</th>
                                  <th>Date</th>
                                </tr>
                              </thead>
                              <tbody>{logList}</tbody>
                            </Table>
                          </Row>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </ModalBody>
                <ModalFooter>
                  <Button
                    color="info"
                    onClick={this.createSinglePdf.bind(this)}
                    style={{ marginLeft: "10px", marginRight: "auto" }}
                    disabled={
                      !status ||
                      status.label !== "Approved" ||
                      this.state.Application.StatusTypeId !==
                        this.state.Application.LastStatusTypeId
                    }
                  >
                    Regenerate contract
                  </Button>
                  <Button
                    color="primary"
                    onClick={this.save.bind(this)}
                    disabled={!this.validateForm()}
                  >
                    Save
                  </Button>{" "}
                  <Button
                    color="secondary"
                    onClick={this.props.toggle.bind(this)}
                  >
                    Cancel
                  </Button>
                </ModalFooter>
              </Modal>
            </Col>
          </Row>
        </div>
      </>
    );
  }

  onInputClick = (event) => {
    event.target.value = "";
  };

  handleStatusChange = (selectedOption) => {
    let modalState = this.state;
    modalState.Application.StatusTypeId = selectedOption.value;
    this.setState(modalState);
  };

  resetModal = () => {
    return new Promise((resolve, reject) => {
      this.setState(this.getModalState(), resolve(true));
    });
  };

  validateForm() {
    return (
      this.state.Application.StatusTypeId.length > 0 &&
      this.state.Application.Plan.length > 0 &&
      this.state.Application.Description.length > 0 &&
      (this.state.Application.Plan !== "S" ||
        (this.state.Application.Plan === "S" &&
          this.state.Application.LevelId &&
          this.state.Application.LevelId.length > 0 &&
          this.state.Application.CampusId &&
          this.state.Application.CampusId.length > 0 &&
          this.state.Application.Period &&
          this.state.Application.Period.length > 0 &&
          this.state.Application.FinancialPlan &&
          this.state.Application.FinancialPlan.length > 0))
    );
  }

  handleUserInput = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    let modalState = this.state;
    modalState.Application[name] = value;

    this.setState(modalState);
  };

  onOpened() {
    this.refreshCourses().then(() => {
      this.refreshCampuses().then(() => {
        this.openApplicationModal();
      });
    });
  }

  closeApplicationModal() {
    this.props.toggle().then(() => {
      this.resetModal().then(() => {
        if (this.props.callback && typeof this.props.callback === "function") {
          this.props.callback();
        }
      });
    });
  }

  refreshStatusTypeList = () => {
    return new Promise((resolve, reject) => {
      axios.get("selfEnrollstatustypes").then((response) => {
        let mainState = this.state;

        mainState.StatusTypeList = [];

        response.data.data.map((statusType, index) =>
          mainState.StatusTypeList.push({
            value: statusType.id,
            label: statusType.name,
          })
        );

        this.setState(mainState, resolve(true));
      });
    });
  };

  openApplicationModal() {
    axios
      .get("selfenroll/GetSelfEnrollLogs?id=" + this.props.id)
      .then((response) => {
        let modalState = this.state;

        modalState.Application.Id = response.data.data.id;
        modalState.Application.Plan = response.data.data.plan;
        modalState.Application.FinancialPlan = response.data.data.financialPlan;
        modalState.Application.Period = response.data.data.period;
        modalState.Application.LevelId = response.data.data.levelId;
        modalState.Application.CampusId = response.data.data.campusId;
        modalState.Application.Email = response.data.data.email;
        modalState.Application.PhoneNumber = response.data.data.phoneNumber;
        modalState.Application.Street = response.data.data.street;
        modalState.Application.Street2 = response.data.data.street2;
        modalState.Application.City = response.data.data.city;
        modalState.Application.State = response.data.data.state;
        modalState.Application.Zip = response.data.data.zip;
        modalState.Application.OtherReason = response.data.data.otherReason;
        modalState.Application.LevelReason = response.data.data.levelReason;
        modalState.Application.Semester = response.data.data.semester;
        modalState.Application.SemesterStartDate =
          response.data.data.semesterStartDate;
        modalState.Application.StudentId = response.data.data.student.id;
        modalState.Application.StudentI20 = response.data.data.student.i20;
        modalState.Application.StudentName = response.data.data.student.name;
        modalState.Application.StudentVisaTypeName =
          response.data.data.student.visaType.name;
        modalState.Application.StudentBirthdate =
          response.data.data.student.birthDate;
        modalState.Application.StartedBeforeSeptember =
          dateFns.parseISO(response.data.data.student.firstEnrolledStatusDate) <
          new Date("2023-09-01");

        response.data.data.selfEnrollLogList.map((log, index) => {
          modalState.Application.LogList.push({
            id: log.id,
            description: log.description,
            statusName: log.status.name,
            statusId: log.status.id,
            userName:
              log.user.name &&
              log.user.name.slice(0, log.user.name.lastIndexOf("@")),
            createdDate: dateFns.parseISO(log.createdDate),
          });
        });

        let lastItem = modalState.Application.LogList.sort(
          (a, b) => new Date(b.createdDate) - new Date(a.createdDate)
        )[0];

        modalState.Application.StatusTypeId = lastItem.statusId;
        modalState.Application.LastStatusTypeId = lastItem.statusId;

        modalState.Application.DocumentSignatureId =
          response.data.data.documentSignature.id;

        modalState.Application.DocumentSignatureFileName =
          response.data.data.documentSignature.fileName;

        modalState.Application.DocumentInitialsId =
          response.data.data.documentInitials.id;

        modalState.Application.DocumentInitialsFileName =
          response.data.data.documentInitials.fileName;

        this.setState(modalState);
      })
      .then(() => {
        this.refreshStatusTypeList();
      });
  }

  onCloseModal = () => {
    this.resetModal();
  };

  errorClass(error) {
    return error.length === 0 ? "" : "is-invalid";
  }

  async save() {
    let status = this.state.StatusTypeList.find(
      (x) => x.value === this.state.Application.StatusTypeId
    );

    let lastStatus = this.state.StatusTypeList.find(
      (x) => x.value === this.state.Application.LastStatusTypeId
    );

    if (status.label === "New") {
      await confirm({
        title: <>Warning</>,
        message: "The 'New' status cannot be picked.",
        cancelText: null,
      });
    } else {
      let selfEnrollLogList = [];
      selfEnrollLogList.push({
        Description: this.state.Application.Description,
        Status: { Id: this.state.Application.StatusTypeId },
      });

      let selfEnroll = {
        Id: this.props.id,
        Plan: this.state.Application.Plan,
        Email: this.state.Application.Email,
        PhoneNumber: this.state.Application.PhoneNumber,
        Street: this.state.Application.Street,
        Street2: this.state.Application.Street2,
        City: this.state.Application.City,
        State: this.state.Application.State,
        Zip: this.state.Application.Zip,
        SelfEnrollLogList: selfEnrollLogList,
      };

      if (this.state.Application.Plan === "S") {
        selfEnroll.FinancialPlan = this.state.Application.FinancialPlan;
        selfEnroll.Period = this.state.Application.Period;
        selfEnroll.LevelId = this.state.Application.LevelId;
        selfEnroll.CampusId = this.state.Application.CampusId;
      }

      await axios.post("selfEnroll/saveLog", selfEnroll).then((response) => {
        this.createPdf(lastStatus.label, status.label).then(() => {
          this.props.toggle().then(() => {
            this.resetModal().then(() => {
              if (
                this.props.callback &&
                typeof this.props.callback === "function"
              ) {
                this.props.callback();
              }
            });
          });
        });
      });
    }
  }

  async loadImage(id) {
    const response = await axios.get("documents/getFileByDate?id=" + id, {
      responseType: "blob",
    });

    const url = window.URL.createObjectURL(
      new Blob([response.data], {
        type: "image/png",
      })
    );

    return url;
  }

  createPdf = async (lastStatus, newStatus) => {
    return new Promise(async (resolve, reject) => {
      if (
        newStatus === "Approved" &&
        newStatus !== lastStatus &&
        this.state.Application.Plan === "S"
      ) {
        let signPicture = null;
        let initialsPicture = null;

        signPicture = await this.loadImage(
          this.state.Application.DocumentSignatureId
        );

        initialsPicture = await this.loadImage(
          this.state.Application.DocumentInitialsId
        );

        let course = this.state.courses.find(
          (x) => x.value == this.state.Application.LevelId
        );

        let balanceMonths =
          this.state.Application.FinancialPlan === "1"
            ? 1
            : this.state.Application.FinancialPlan === "2"
            ? 3
            : 0;

        let firstPaymentDate = dateFns.parseISO(
          this.state.Application.SemesterStartDate
        );

        let secondPaymentDate = null;
        let thirdPaymentDate = null;

        let startedBeforeSeptember =
          this.state.Application.StartedBeforeSeptember;

        let tuitionCost = startedBeforeSeptember
          ? course.tuitionCost
          : course.newTuitionCost;
        let cardFee = tuitionCost * 0.04;

        let tuition1st = tuitionCost;
        let cardFee1st = cardFee;
        let tuition2nd = null;
        let cardFee2nd = null;
        let tuition3rd = null;
        let cardFee3rd = null;

        if (balanceMonths === 3) {
          tuition1st = tuitionCost / 2;
          cardFee1st = (tuitionCost / 2) * 0.04;

          tuition2nd = tuitionCost / 4;
          cardFee2nd = (tuitionCost / 4) * 0.04;

          tuition3rd = tuitionCost / 4;
          cardFee3rd = (tuitionCost / 4) * 0.04;

          secondPaymentDate = dateFns.addMonths(firstPaymentDate, 1);
          thirdPaymentDate = dateFns.addMonths(firstPaymentDate, 2);
        }

        let options = {
          isSelfEnrollment: true,
          canSchoolSendMessage: true,

          tuitionCost: tuitionCost,
          totalCost: tuitionCost + cardFee,
          balanceCost: tuitionCost + cardFee,
          cardFee: cardFee,

          firstPaymentCost: tuition1st,
          firstPaymentFee: cardFee1st,

          secondPaymentCost: tuition2nd,
          secondPaymentFee: cardFee2nd,

          thirdPaymentCost: tuition3rd,
          thirdPaymentFee: cardFee3rd,

          balanceMonths: balanceMonths,
          firstPaymentDate: firstPaymentDate,
          secondPaymentDate: secondPaymentDate,
          thirdPaymentDate: thirdPaymentDate,

          date: new Date(),
          currentSemesterName: this.state.Application.Semester,
          currentUser: this.state.currentUser,
        };

        let studentObj = {
          id: this.state.Application.StudentId,
          name: this.state.Application.StudentName,
          street:
            this.state.Application.Street +
            " " +
            this.state.Application.Street2,
          city: this.state.Application.City,
          state: this.state.Application.State,
          zip: this.state.Application.Zip,
          email: this.state.Application.Email,
          cellPhone: this.state.Application.PhoneNumber,
          signatureImg: signPicture,
          initialsImg: initialsPicture,
          signatureToken: "",
          i20: this.state.Application.StudentI20,
          visaType: this.state.Application.StudentVisaTypeName,
          dateOfBirth: this.state.Application.StudentBirthdate,
        };

        FinancialContractPdf(studentObj, options);

        resolve(true);
      } else {
        resolve(true);
      }
    });
  };

  createSinglePdf = () => {
    return new Promise((resolve, reject) => {
      if (this.state.Application.Plan === "S") {
        let signPicture = null;
        let initialsPicture = null;

        let course = this.state.courses.find(
          (x) => x.value == this.state.Application.LevelId
        );

        axios
          .get(
            "documents/getFileByDate?id=" +
              this.state.Application.DocumentSignatureId,
            {
              responseType: "blob",
            }
          )
          .then((response) => {
            const url = window.URL.createObjectURL(
              new Blob([response.data], {
                type: "image/png",
              })
            );

            signPicture = url;
          })
          .then(() => {
            axios
              .get(
                "documents/getFileByDate?id=" +
                  this.state.Application.DocumentInitialsId,
                {
                  responseType: "blob",
                }
              )
              .then((response) => {
                const url = window.URL.createObjectURL(
                  new Blob([response.data], {
                    type: "image/png",
                  })
                );

                initialsPicture = url;
              })
              .then(() => {
                let balanceMonths =
                  this.state.Application.FinancialPlan === "1"
                    ? 1
                    : this.state.Application.FinancialPlan === "2"
                    ? 3
                    : 0;

                let firstPaymentDate = dateFns.parseISO(
                  this.state.Application.SemesterStartDate
                );

                let secondPaymentDate = null;
                let thirdPaymentDate = null;

                let startedBeforeSeptember =
                  this.state.Application.StartedBeforeSeptember;

                let tuitionCost = startedBeforeSeptember
                  ? course.tuitionCost
                  : course.newTuitionCost;
                let cardFee = tuitionCost * 0.04;

                let tuition1st = tuitionCost;
                let cardFee1st = cardFee;
                let tuition2nd = null;
                let cardFee2nd = null;
                let tuition3rd = null;
                let cardFee3rd = null;

                if (balanceMonths === 3) {
                  tuition1st = tuitionCost / 2;
                  cardFee1st = (tuitionCost / 2) * 0.04;

                  tuition2nd = tuitionCost / 4;
                  cardFee2nd = (tuitionCost / 4) * 0.04;

                  tuition3rd = tuitionCost / 4;
                  cardFee3rd = (tuitionCost / 4) * 0.04;

                  secondPaymentDate = dateFns.addMonths(firstPaymentDate, 1);
                  thirdPaymentDate = dateFns.addMonths(firstPaymentDate, 2);
                }

                let options = {
                  isSelfEnrollment: true,
                  canSchoolSendMessage: true,

                  tuitionCost: tuitionCost,
                  totalCost: tuitionCost + cardFee,
                  balanceCost: tuitionCost + cardFee,
                  cardFee: cardFee,

                  firstPaymentCost: tuition1st,
                  firstPaymentFee: cardFee1st,

                  secondPaymentCost: tuition2nd,
                  secondPaymentFee: cardFee2nd,

                  thirdPaymentCost: tuition3rd,
                  thirdPaymentFee: cardFee3rd,

                  balanceMonths: balanceMonths,
                  firstPaymentDate: firstPaymentDate,
                  secondPaymentDate: secondPaymentDate,
                  thirdPaymentDate: thirdPaymentDate,

                  date: new Date(),
                  currentSemesterName: this.state.Application.Semester,
                  currentUser: this.state.currentUser,
                };

                let studentObj = {
                  id: this.state.Application.StudentId,
                  name: this.state.Application.StudentName,
                  street:
                    this.state.Application.Street +
                    " " +
                    this.state.Application.Street2,
                  city: this.state.Application.City,
                  state: this.state.Application.State,
                  zip: this.state.Application.Zip,
                  email: this.state.Application.Email,
                  cellPhone: this.state.Application.PhoneNumber,
                  signatureImg: signPicture,
                  initialsImg: initialsPicture,
                  signatureToken: "",
                  i20: this.state.Application.StudentI20,
                  visaType: this.state.Application.StudentVisaTypeName,
                  dateOfBirth: this.state.Application.StudentBirthdate,
                };

                FinancialContractPdf(studentObj, options);
              });
          })
          .then(() => resolve(true));
      } else {
        resolve(true);
      }
    });
  };

  planOptions = [
    { value: "S", label: "Keep studying" },
    { value: "B", label: "Take a break" },
    { value: "C", label: "Transfer to college" },
    { value: "O", label: "Other" },
  ];

  periodOptions = [
    { value: "M", label: "Morning" },
    { value: "E", label: "Evening" },
    { value: "MT", label: "Mon/Tue" },
    { value: "WT", label: "Wed/Thu" },
    { value: "HW", label: "Wed" },
  ];

  financialPlanOptions = [
    { value: "1", label: "One Payment" },
    { value: "2", label: "Three Payments" },
    { value: "3", label: "One-Disc" },
    { value: "4", label: "Two-Disc" },
    { value: "5", label: "Three-Disc" },
  ];

  refreshCourses = () => {
    return new Promise((resolve, reject) => {
      let modalState = this.state;

      this.props.tables.courses.map((course, index) =>
        modalState.courses.push({
          value: course.id,
          label: course.name,
          tuitionCost: course.tuitionCost,
          newTuitionCost: course.newTuitionCost,
        })
      );

      this.setState(modalState, resolve(true));
    });
  };

  handleCourseChange = (selectedOption) => {
    let modalState = this.state;

    modalState.Application.LevelId = selectedOption.value;

    this.setState(modalState);
  };

  refreshCampuses = () => {
    return new Promise((resolve, reject) => {
      let modalState = this.state;

      this.props.tables.campuses.map((campus, index) =>
        modalState.campuses.push({
          value: campus.id,
          label: campus.name,
        })
      );

      this.setState(modalState, resolve(true));
    });
  };

  handleCampusChange = (selectedOption) => {
    let modalState = this.state;

    modalState.Application.CampusId = selectedOption.value;

    this.setState(modalState);
  };

  handlePeriodChange = (selectedOption) => {
    let modalState = this.state;

    modalState.Application.Period = selectedOption.value;

    this.setState(modalState);
  };

  handleFinancialPlanChange = (selectedOption) => {
    let modalState = this.state;

    modalState.Application.FinancialPlan = selectedOption.value;

    this.setState(modalState);
  };

  handlePlanChange = (selectedOption) => {
    let modalState = this.state;

    modalState.Application.Plan = selectedOption.value;

    this.setState(modalState);
  };
}

export default SelfEnrollmentModal;
