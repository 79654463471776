/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Row,
  Col,
  Table,
  Button,
  Collapse,
} from "reactstrap";
import axios from "axios";
import myData from "../Countries.json";
import { FormatMonthYearDateList, editMode } from "helpers/Utils.js";
import RenewI20 from "components/RenewI20/RenewI20.jsx";
import ConsecutiveAbsences from "components/Student/ConsecutiveAbsences.jsx";
import StudentEdit2 from "components/Student/Student2.component.edit.jsx";
import AvailableSeats from "../components/AvailableSeats/AvailableSeats.jsx";
import StudentClassView from "components/Student/Student.class.view";
import Can from "components/Can/Can";
import Dashboard2Teacher from "../components/Dashboard/Dashboard2.teacher.jsx";
import { SavePdf } from "components/Report/GeneratePdf.js";
import * as dateFns from "date-fns";
import StudentFullPresent from "../components/Student/Student.FullPresent";
import MessageEdit from "components/Message/Message.component.edit.jsx";
import { Doughnut, Bar } from "react-chartjs-2";
import { colorArray } from "colors.js";

class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = this.getMainState();
  }

  render() {
    let countries = this.state.studentsByCountry.map((item) => {
      return (
        <tr key={item.countryName}>
          <td>
            <div className={"flag flag-" + item.name}></div>
          </td>

          <td>
            <a
              onClick={this.toggleStudentClassViewModal.bind(
                this,
                item.name.toUpperCase(),
                item.countryName
              )}
              href="#"
            >
              {item.countryName}
            </a>
          </td>

          <td>{item.total}</td>
          <td className="text-right">{item.percent}%</td>
        </tr>
      );
    });

    let birthdaysOfWeek = this.state.birthdaysOfWeek.map((item) => {
      return (
        <tr key={item.id + item.campusName}>
          <Can
            feature="/admin/student"
            yes={() => (
              <td>
                <a
                  onClick={this.setStudentIdAndOpenModal.bind(this, item.id)}
                  href="#"
                >
                  {item.isToday ? <strong>{item.name}</strong> : item.name}
                </a>
              </td>
            )}
            no={() => <td>{item.name}</td>}
          ></Can>

          <Can
            feature="Student_Send_Message"
            yes={() => (
              <td style={{ textAlign: "center" }}>
                <a
                  className="far fa-envelope"
                  onClick={this.setStudentIdAndOpenMessage.bind(
                    this,
                    item.id,
                    item.name
                  )}
                  href="#"
                ></a>
              </td>
            )}
          ></Can>

          <td>
            {item.isToday ? (
              <strong>{item.campusName}</strong>
            ) : (
              item.campusName
            )}
          </td>

          <td className="text-right">
            {item.isToday ? (
              <strong>{item.dateOfBirth}</strong>
            ) : (
              item.dateOfBirth
            )}
          </td>
        </tr>
      );
    });

    let teacherClasses = this.state.teacherClasses.map((classe, index) => {
      return (
        <Col lg="3" md="6" sm="6" key={index}>
          <Card
            className="card-stats"
            onClick={this.toggleDashboardTeacherModal.bind(this, classe)}
            style={{ cursor: "pointer" }}
          >
            <CardBody>
              <Row>
                <Col md="3" xs="3">
                  <div className="icon-big text-center icon-warning">
                    <i className="nc-icon nc-paper text-warning" />
                  </div>
                </Col>
                <Col md="9" xs="9">
                  <div className="numbers">
                    <p className="card-category"></p>
                    <CardTitle tag="p" style={{ fontSize: "14px" }}>
                      {classe.code}
                    </CardTitle>
                    <p />
                  </div>
                </Col>
              </Row>
            </CardBody>
            <CardFooter>
              <hr />

              {classe.totalPendencies > 0 ? (
                <div
                  className="stats"
                  style={{ color: "red", fontWeight: "bold" }}
                >
                  <i className="fas fa-exclamation" style={{ color: "red" }} />
                  {classe.totalPendencies + " records to be checked"}
                </div>
              ) : (
                <div
                  className="stats"
                  style={{ color: "green", fontWeight: "bold" }}
                >
                  <i className="fas fa-check" style={{ color: "green" }} />
                  No late records to check
                </div>
              )}
            </CardFooter>
          </Card>
        </Col>
      );
    });

    let classGroups = this.state.classGroups.map((item, index) => {
      return (
        <tr key={index}>
          <td>
            <a
              onClick={this.toggleStudentClassGroupModal.bind(
                this,
                item.name.toUpperCase()
              )}
              href="#"
            >
              {item.name}
            </a>
          </td>

          <td> {item.campusName} </td>
          <td> {item.level} </td>

          <td className="text-right">
            <strong>{item.total}</strong>
          </td>
        </tr>
      );
    });

    let totalBirthdaysToday =
      this.state.birthdaysOfWeek.filter((x) => x.isToday === true).length >
      0 ? (
        <strong>
          {" "}
          {" (" +
            this.state.birthdaysOfWeek.filter((x) => x.isToday === true)
              .length +
            " today)"}{" "}
        </strong>
      ) : (
        ""
      );

    let blockedStudents = this.state.blockedStudents.map((item, index) => {
      return (
        <tr key={index}>
          <td>{item.name}</td>

          <td> {item.classCode} </td>
          <td className="text-right"> {item.createdDate} </td>
        </tr>
      );
    });

    return (
      <>
        <div className="content">
          {blockedStudents && blockedStudents.length > 0 && (
            <Row>
              <Col md="6">
                <Card style={{ backgroundColor: "#ffbaba" }}>
                  <CardHeader>
                    <CardTitle tag="h5">Blocked Students</CardTitle>
                  </CardHeader>
                  <CardBody>
                    <Table>
                      <thead className="text-primary">
                        <tr>
                          <th>Name</th>
                          <th>Class Code</th>
                          <th className="text-right">Date</th>
                        </tr>
                      </thead>
                      <tbody>{blockedStudents}</tbody>
                    </Table>
                  </CardBody>
                  <CardFooter>
                    <hr />
                    <div className="stats" style={{ fontWeight: "bold" }}>
                      {/* <i className="fa fa-sync-alt" />  */}* The following
                      students are not allowed to be in class
                    </div>
                  </CardFooter>
                </Card>
              </Col>
            </Row>
          )}
          <Row>
            <Can
              feature="Dashboard_Total_Students_Card"
              yes={() => (
                <Col lg="3" md="6" sm="6">
                  <Card className="card-stats">
                    <CardBody>
                      <Row>
                        <Col md="3" xs="3">
                          <div className="icon-big text-center icon-warning">
                            <i className="nc-icon nc-single-02 text-warning" />
                          </div>
                        </Col>
                        <Col md="9" xs="9">
                          <div className="numbers">
                            <p className="card-category">Total students</p>
                            <CardTitle tag="p">
                              {this.state.totalStudents}
                            </CardTitle>
                            <p />
                          </div>
                        </Col>
                      </Row>
                    </CardBody>
                    <CardFooter>
                      <hr />
                      <div className="stats">
                        <i className="fas fa-sync-alt" /> Updated Now
                      </div>
                    </CardFooter>
                  </Card>
                </Col>
              )}
            ></Can>

            <Can
              feature="Dashboard_Teacher_Classes"
              yes={() => <React.Fragment>{teacherClasses}</React.Fragment>}
            ></Can>

            <Can
              feature="Dashboard_Renew_I20_Card"
              yes={() => (
                <Col lg="3" md="6" sm="6">
                  <Card
                    className="card-stats"
                    onClick={this.toggleRenewI20Modal}
                    style={{ cursor: "pointer" }}
                  >
                    <CardBody>
                      <Row>
                        <Col md="3" xs="3">
                          <div className="icon-big text-center icon-warning">
                            <i className="nc-icon nc-single-copy-04 text-primary" />
                          </div>
                        </Col>
                        <Col md="9" xs="9">
                          <div className="numbers">
                            <p className="card-category">{"I-20 renew"}</p>
                            <CardTitle tag="p">
                              {" "}
                              {this.state.totalI20EndDate}
                            </CardTitle>
                            <p />
                          </div>
                        </Col>
                      </Row>
                    </CardBody>
                    <CardFooter>
                      <hr />
                      <div className="stats">
                        <i className="far fa-hand-pointer" /> Click for details
                      </div>
                    </CardFooter>
                  </Card>
                </Col>
              )}
            ></Can>

            <Can
              feature="Dashboard_Consecutive_Absences_Card"
              yes={() => (
                <Col lg="3" md="6" sm="6">
                  <Card
                    className="card-stats"
                    onClick={this.toggleConsecutiveAbsencesModal}
                    style={{ cursor: "pointer" }}
                  >
                    <CardBody>
                      <Row>
                        <Col md="3" xs="3">
                          <div className="icon-big text-center icon-warning">
                            <i className="fas fa-question text-danger" />
                          </div>
                        </Col>
                        <Col md="9" xs="9">
                          <div className="numbers">
                            <p className="card-category">Consec. Absences</p>
                            <CardTitle tag="p">
                              {this.state.totalConsecutiveAbsences}
                            </CardTitle>
                            <p />
                          </div>
                        </Col>
                      </Row>
                    </CardBody>
                    <CardFooter>
                      <hr />
                      <div className="stats">
                        <i className="far fa-hand-pointer" /> Click for details
                      </div>
                    </CardFooter>
                  </Card>
                </Col>
              )}
            ></Can>

            <Can
              feature="Dashboard_Full_Present_Card"
              yes={() => (
                <Col lg="3" md="6" sm="6">
                  <Card
                    className="card-stats"
                    onClick={this.toggleStudentsFullPresentModal}
                    style={{ cursor: "pointer" }}
                  >
                    <CardBody>
                      <Row>
                        <Col md="3" xs="3">
                          <div className="icon-big text-center icon-warning">
                            <i className="far fa-thumbs-up text-success" />
                          </div>
                        </Col>
                        <Col md="9" xs="9">
                          <div className="numbers">
                            <p className="card-category">100% Attendance</p>
                            <CardTitle tag="p">
                              {this.state.totalFullPresent}
                            </CardTitle>
                            <p />
                          </div>
                        </Col>
                      </Row>
                    </CardBody>
                    <CardFooter>
                      <hr />
                      <div className="stats">
                        <i className="far fa-hand-pointer" /> Click for details
                      </div>
                    </CardFooter>
                  </Card>
                </Col>
              )}
            ></Can>
          </Row>

          <Row>
            {this.currentSemesterSelected() == false && (
              <Can
                feature="Dashboard_Enrolled_Students_Card"
                yes={() => (
                  <Col lg="3" md="6" sm="6">
                    <Card className="card-stats">
                      <CardBody>
                        <Row>
                          <Col md="3" xs="3">
                            <div className="icon-big text-center icon-warning">
                              <i className="nc-icon nc-single-02 text-warning" />
                            </div>
                          </Col>
                          <Col md="9" xs="9">
                            <div className="numbers">
                              <p className="card-category">
                                {"Enrolled till last day"}
                              </p>
                              <CardTitle tag="p">
                                {this.state.totalEnrolledStudents}
                              </CardTitle>
                              <p />
                            </div>
                          </Col>
                        </Row>
                      </CardBody>
                      <CardFooter>
                        <hr />
                        <div className="stats">
                          <i className="fas fa-sync-alt" /> Updated Now
                        </div>
                      </CardFooter>
                    </Card>
                  </Col>
                )}
              ></Can>
            )}

            <Can
              feature="Dashboard_Enrolled_With_Balance_Chart_Card"
              yes={() => (
                <Col lg="3" md="6" sm="6">
                  <Card className="card-stats">
                    <CardBody>
                      <Row>
                        <Col md="3" xs="3">
                          <div className="icon-big text-center icon-warning">
                            <i className="nc-icon nc-sound-wave text-danger" />
                          </div>
                        </Col>
                        <Col md="9" xs="9">
                          <div className="numbers">
                            <p className="card-category">
                              {"Enrolled with balance"}
                            </p>
                            <CardTitle tag="p">
                              {this.state.enrolledWithBalance}
                            </CardTitle>
                            <p />
                          </div>
                        </Col>
                      </Row>
                    </CardBody>
                    <CardFooter>
                      <hr />
                      <div className="stats">{"Enrolled with balance > 0"}</div>
                    </CardFooter>
                  </Card>
                </Col>
              )}
            ></Can>

            <Can
              feature="Dashboard_Overdue_Invoices_Card"
              yes={() => (
                <Col lg="3" md="6" sm="6">
                  <Card
                    className="card-stats"
                    // onClick={this.toggleRenewI20Modal}
                    // style={{ cursor: "pointer" }}
                  >
                    <CardBody>
                      <Row>
                        <Col md="3" xs="3">
                          <div className="icon-big text-center icon-warning">
                            <i className="nc-icon nc-money-coins text-success" />
                          </div>
                        </Col>
                        <Col md="9" xs="9">
                          <div className="numbers">
                            <p className="card-category">
                              {"Enrolled invoices"}
                            </p>
                            <CardTitle tag="p">
                              {" "}
                              {"$" + this.state.overdueInvoices.toFixed(2)}
                            </CardTitle>
                            <p />
                          </div>
                        </Col>
                      </Row>
                    </CardBody>
                    <CardFooter>
                      <hr />
                      <div className="stats">Enrolled students</div>
                    </CardFooter>
                  </Card>
                </Col>
              )}
            ></Can>

            <Can
              feature="Dashboard_Non_Enrolled_Invoices_Card"
              yes={() => (
                <Col lg="3" md="6" sm="6">
                  <Card
                    className="card-stats"
                    // onClick={this.toggleConsecutiveAbsencesModal}
                    // style={{ cursor: "pointer" }}
                  >
                    <CardBody>
                      <Row>
                        <Col md="3" xs="3">
                          <div className="icon-big text-center icon-warning">
                            <i className="nc-icon nc-money-coins text-danger" />
                          </div>
                        </Col>
                        <Col md="9" xs="9">
                          <div className="numbers">
                            <p className="card-category">
                              {"Non-enrolled invoices"}
                            </p>
                            <CardTitle tag="p">
                              {"$" + this.state.nonEnrolledInvoices.toFixed(2)}
                            </CardTitle>
                            <p />
                          </div>
                        </Col>
                      </Row>
                    </CardBody>
                    <CardFooter>
                      <hr />
                      <div className="stats">Non-enrolled students</div>
                    </CardFooter>
                  </Card>
                </Col>
              )}
            ></Can>

            <Can
              feature="Dashboard_Blocked_Students_Card"
              yes={() => (
                <Col lg="3" md="6" sm="6">
                  <Card
                    className="card-stats"
                    // onClick={this.toggleStudentsFullPresentModal}
                    // style={{ cursor: "pointer" }}
                  >
                    <CardBody>
                      <Row>
                        <Col md="3" xs="3">
                          <div className="icon-big text-center icon-warning">
                            <i className="fas fa-ban text-danger" />
                          </div>
                        </Col>
                        <Col md="9" xs="9">
                          <div className="numbers">
                            <p className="card-category">Blocked students</p>
                            <CardTitle tag="p">
                              {this.state.totalBlockedStudents}
                            </CardTitle>
                            <p />
                          </div>
                        </Col>
                      </Row>
                    </CardBody>
                    <CardFooter>
                      <hr />
                      <div className="stats">All students</div>
                    </CardFooter>
                  </Card>
                </Col>
              )}
            ></Can>
          </Row>

          <Row>
            <Can
              feature="Dashboard_Campus_Chart_Card"
              yes={() => (
                <Col md="3">
                  <Card>
                    <CardHeader>
                      <CardTitle tag="h5">Students by campus</CardTitle>
                    </CardHeader>
                    <CardBody>{this.renderDoughnutCampus()}</CardBody>
                    <CardFooter>
                      <hr />
                      <div className="stats">
                        <i className="fa fa-sync-alt" /> Updated now
                      </div>
                    </CardFooter>
                  </Card>
                </Col>
              )}
            ></Can>

            <Can
              feature="Dashboard_Period_Chart_Card"
              yes={() => (
                <Col md="3">
                  <Card>
                    <CardHeader>
                      <CardTitle tag="h5">Students by period</CardTitle>
                    </CardHeader>
                    <CardBody>{this.renderDoughnutPeriod()}</CardBody>
                    <CardFooter>
                      <hr />
                      <div className="stats">
                        <i className="fa fa-sync-alt" /> Updated now
                      </div>
                    </CardFooter>
                  </Card>
                </Col>
              )}
            ></Can>

            <Can
              feature="Dashboard_Level_Chart_Card"
              yes={() => (
                <Col md="3">
                  <Card>
                    <CardHeader>
                      <CardTitle tag="h5">Students by level</CardTitle>
                    </CardHeader>
                    <CardBody>{this.renderDoughnutLevel()}</CardBody>
                    <CardFooter>
                      <hr />
                      <div className="stats">
                        <i className="fa fa-sync-alt" /> Updated now
                      </div>
                    </CardFooter>
                  </Card>
                </Col>
              )}
            ></Can>

            <Can
              feature="Dashboard_Status_Chart_Card"
              yes={() => (
                <Col md="3">
                  <Card>
                    <CardHeader>
                      <CardTitle tag="h5">Students by status</CardTitle>
                    </CardHeader>
                    <CardBody>{this.renderDoughnutStatus()}</CardBody>
                    <CardFooter>
                      <hr />
                      <div className="stats">
                        <i className="fa fa-sync-alt" /> Updated now
                      </div>
                    </CardFooter>
                  </Card>
                </Col>
              )}
            ></Can>
          </Row>

          <Row>
            <Can
              feature="Dashboard_LessonPlan_Chart_Card"
              yes={() => (
                <Col md="12">
                  <Card>
                    <CardHeader>
                      <CardTitle tag="h5">Lesson plans submitted (%)</CardTitle>
                    </CardHeader>

                    {this.state.lessonPlanChartList.length > 0 && (
                      <CardBody>{this.renderChartLessonPlan()}</CardBody>
                    )}

                    <CardFooter>
                      <hr />
                      <div className="stats">
                        <i className="fa fa-sync-alt" /> Updated now
                      </div>
                    </CardFooter>
                  </Card>
                </Col>
              )}
            ></Can>
          </Row>

          <Row>
            <Can
              feature="Dashboard_Classes"
              yes={() => (
                <Col md="12">
                  <Card>
                    <CardHeader>
                      <CardTitle tag="h5">Classes</CardTitle>
                    </CardHeader>

                    {this.state.classGroups.length > 0 && (
                      <CardBody>{this.renderChartClassGroups()}</CardBody>
                    )}

                    <CardFooter>
                      <hr />
                      <div className="stats">
                        <i className="fa fa-sync-alt" /> Updated now
                      </div>
                    </CardFooter>
                  </Card>
                </Col>
              )}
            ></Can>
          </Row>

          <Row>
            <Can
              feature="Dashboard_Birthday_Card"
              yes={() => (
                <Col md="6">
                  <Card>
                    <CardHeader>
                      <CardTitle tag="h5">
                        Birthdays of the week {totalBirthdaysToday}
                      </CardTitle>

                      {birthdaysOfWeek.length > 0 && (
                        <Button
                          color="info"
                          size="sm"
                          style={{ marginBottom: "20px" }}
                          onClick={this.toggleCollapseBirthdayCard}
                        >
                          Details
                        </Button>
                      )}
                    </CardHeader>
                    <Collapse isOpen={this.state.collapseBirthdayCardIsOpen}>
                      <CardBody>
                        <Table>
                          <thead className="text-primary">
                            <tr>
                              <th>Name</th>

                              <Can
                                feature="Student_Send_Message"
                                yes={() => (
                                  <th style={{ textAlign: "center" }}>
                                    Message
                                  </th>
                                )}
                              ></Can>

                              <th>Campus</th>
                              <th className="text-right">Date</th>
                            </tr>
                          </thead>
                          <tbody>{birthdaysOfWeek}</tbody>
                        </Table>
                      </CardBody>
                      <CardFooter>
                        <hr />
                        <div className="stats">
                          <i className="fa fa-sync-alt" /> Updated now
                        </div>
                      </CardFooter>
                    </Collapse>
                  </Card>
                </Col>
              )}
            ></Can>

            <Can
              feature="Dashboard_Students_By_Country"
              yes={() => (
                <Col md="6">
                  <Card>
                    <CardHeader>
                      <CardTitle tag="h5">Students by country</CardTitle>
                      {/* <p className="card-category">Both campuses</p> */}

                      {this.state.studentsByCountry.length > 1 && (
                        <Button
                          color="info"
                          size="sm"
                          onClick={this.toggleCollapseCountriesCard}
                          style={{ marginBottom: "20px" }}
                        >
                          Details
                        </Button>
                      )}
                    </CardHeader>
                    <Collapse isOpen={this.state.collapseCountriesCardIsOpen}>
                      <CardBody>
                        <Table>
                          <tbody>{countries}</tbody>
                        </Table>
                      </CardBody>

                      {this.state.studentsByCountry.length > 0 && (
                        <CardFooter>
                          <hr />
                          <Row>
                            <h6
                              style={{
                                marginLeft: "25px",
                                marginRight: "auto",
                                marginTop: "15px",
                                textTransform: "inherit",
                              }}
                            >
                              <strong className="text-secondary">
                                {this.state.studentsByCountry.length}
                              </strong>{" "}
                              {this.state.studentsByCountry.length > 1
                                ? "records"
                                : "record"}
                            </h6>

                            <Button
                              color="success"
                              size="sm"
                              style={{
                                textTransform: "inherit",
                                marginRight: "25px",
                                marginBottom: "10px",
                                marginLeft: "1px",
                                marginTop: "10px",
                              }}
                              onClick={SavePdf.bind(
                                this,
                                "Countries",
                                this.state.studentsByCountry,
                                [
                                  {
                                    countryName: "Country",
                                    total: "Total",
                                    percent: "%",
                                  },
                                ]
                              )}
                            >
                              Report
                            </Button>
                          </Row>
                        </CardFooter>
                      )}
                    </Collapse>
                  </Card>
                </Col>
              )}
            ></Can>

            <Can
              feature="Dashboard_Classes"
              yes={() => (
                <Col md="6">
                  <Card>
                    <CardHeader>
                      <CardTitle tag="h5">Classes</CardTitle>

                      {this.state.classGroups.length > 0 && (
                        <Button
                          color="info"
                          size="sm"
                          onClick={this.toggleCollapseClassesCard}
                          style={{ marginBottom: "20px" }}
                        >
                          Details
                        </Button>
                      )}
                    </CardHeader>

                    <Collapse isOpen={this.state.collapseClassesCardIsOpen}>
                      <CardBody>
                        <Table id="tableClassGroups">
                          <thead className="text-primary">
                            <tr>
                              <th>Name</th>
                              <th>Campus</th>
                              <th>Level</th>
                              <th className="text-right">Total</th>
                            </tr>
                          </thead>
                          <tbody>{classGroups}</tbody>
                        </Table>
                      </CardBody>

                      {this.state.classGroups.length > 0 && (
                        <CardFooter>
                          <hr />
                          <Row>
                            <h6
                              style={{
                                marginLeft: "25px",
                                marginRight: "auto",
                                marginTop: "15px",
                                textTransform: "inherit",
                              }}
                            >
                              <strong className="text-secondary">
                                {this.state.classGroups.length}
                              </strong>{" "}
                              {this.state.classGroups.length > 1
                                ? "records"
                                : "record"}
                            </h6>

                            <Button
                              color="success"
                              size="sm"
                              style={{
                                textTransform: "inherit",
                                marginRight: "10px",
                                marginBottom: "10px",
                                marginLeft: "1px",
                                marginTop: "10px",
                              }}
                              onClick={SavePdf.bind(
                                this,
                                "Classes",
                                this.state.classGroups,
                                [
                                  {
                                    name: "Name",
                                    campusName: "Campus",
                                    level: "Level",
                                    total: "Total",
                                  },
                                ]
                              )}
                            >
                              Export to PDF
                            </Button>

                            <Button
                              color="success"
                              size="sm"
                              style={{
                                textTransform: "inherit",
                                marginRight: "25px",
                                marginBottom: "10px",
                                marginLeft: "1px",
                                marginTop: "10px",
                              }}
                              onClick={this.downloadCSVClasses.bind(this)}
                            >
                              Export to CSV
                            </Button>
                          </Row>
                        </CardFooter>
                      )}
                    </Collapse>
                  </Card>
                </Col>
              )}
            ></Can>
          </Row>

          <RenewI20
            isOpen={this.state.openRenewI20Panel}
            toggleRenewI20Modal={this.toggleRenewI20Modal.bind(this)}
            tables={this.props.tables}
          />

          <AvailableSeats
            isOpen={this.state.openAvailableSeatsPanel}
            toggleAvailableSeatsModal={this.toggleAvailableSeatsModal.bind(
              this
            )}
          />
          <StudentEdit2
            isOpen={this.state.openStudentModal}
            toggleStudentModal={this.toggleStudentModal.bind(this)}
            editMode={editMode.EDIT}
            id={this.state.studentId}
            callback={this.refreshAll.bind(this)}
            tables={this.props.tables}
          />

          <StudentFullPresent
            isOpen={this.state.openFullPresentPanel}
            toggleStudentModal={this.toggleStudentsFullPresentModal.bind(this)}
            tables={this.props.tables}
          />
          <StudentClassView
            url={"students/byCountry?countryId="}
            searchId={this.state.selectCountryIdForStudentClassViewModal}
            isOpen={this.state.openStudentClassViewModal}
            toggleStudentModal={this.toggleStudentClassViewModal.bind(
              this,
              this.state.selectCountryIdForStudentClassViewModal,
              this.state.selectCountryNameForStudentClassViewModal
            )}
            tables={this.props.tables}
            headerName={this.state.selectCountryNameForStudentClassViewModal}
          />
          <Dashboard2Teacher
            isOpen={this.state.openDashboardTeacherModal}
            toggleStudentModal={this.toggleDashboardTeacherModal.bind(
              this,
              this.state.selectClassForDashboardTeacherModal
            )}
            classe={this.state.selectClassForDashboardTeacherModal}
            callback={this._refreshDashboard.bind(this)}
          />
          <MessageEdit
            isOpen={this.state.openModalMessage}
            toggleMessageModal={this.toggleMessageModal.bind(this)}
            editMode={editMode.INSERT}
            tables={this.props.tables}
            StudentId={this.state.studentId}
            StudentName={this.state.studentName}
          />
          <ConsecutiveAbsences
            isOpen={this.state.openConsecutiveAbsencesPanel}
            toggle={this.toggleConsecutiveAbsencesModal.bind(this)}
            tables={this.props.tables}
          />
          <StudentClassView
            url={"students/byclassgroup?classGroup="}
            searchId={this.state.classGroupForStudentClassGroupModal}
            isOpen={this.state.openStudentClassGroupModal}
            toggleStudentModal={this.toggleStudentClassGroupModal.bind(
              this,
              this.state.classGroupForStudentClassGroupModal
            )}
            tables={this.props.tables}
            headerName={this.state.classGroupForStudentClassGroupModal}
          />
        </div>
      </>
    );
  }

  chartJsData(resultSet) {
    return {
      datasets: [
        {
          data: resultSet.map(function (r) {
            return r.total;
          }),
          backgroundColor: resultSet.map(function (r, i) {
            return colorArray[i].hex;
          }),
        },
      ],

      labels: resultSet.map(function (c) {
        return c.name;
      }),
    };
  }

  chartJsDataWithTotal(resultSet) {
    return {
      datasets: [
        {
          data: resultSet.map(function (r) {
            return r.total;
          }),
          backgroundColor: resultSet.map(function (r, i) {
            return colorArray[i].hex;
          }),
        },
      ],

      labels: resultSet.map(function (c) {
        return c.name + ":  " + c.total;
      }),
    };
  }

  legendOptions(show) {
    return {
      display: show,
      position: "right",
    };
  }

  renderDoughnutCampus = () => {
    return (
      <Doughnut
        data={this.chartJsData(this.state.campusChartList)}
        width={200}
        height={200}
        options={{ maintainAspectRatio: false }}
        legend={{
          display: true,
          position: "right",
          align: "center",
        }}
      />
    );
  };

  renderChartLessonPlan = () => {
    return (
      <Bar
        data={this.chartJsData(this.state.lessonPlanChartList)}
        width={200}
        height={200}
        options={{
          maintainAspectRatio: false,
          scales: {
            yAxes: [
              {
                ticks: {
                  beginAtZero: true,
                },
              },
            ],
          },
        }}
        legend={this.legendOptions(false)}
      />
    );
  };

  renderChartClassGroups = () => {
    let list = [...this.state.classGroups];
    let sortedList = list.sort(function (a, b) {
      return a.total - b.total || a.name.localeCompare(b.name);
    });

    return (
      <Bar
        data={this.chartJsDataWithTotal(sortedList)}
        width={200}
        height={200}
        options={{
          maintainAspectRatio: false,
          scales: {
            yAxes: [
              {
                ticks: {
                  beginAtZero: true,
                },
              },
            ],
          },
        }}
        legend={this.legendOptions(false)}
      />
    );
  };

  renderDoughnutPeriod = () => {
    return (
      <Doughnut
        data={this.chartJsData(this.state.periodChartList)}
        width={200}
        height={200}
        options={{ maintainAspectRatio: false }}
        legend={this.legendOptions(true)}
      />
    );
  };

  renderDoughnutLevel = () => {
    return (
      <Doughnut
        data={this.chartJsData(this.state.levelChartList)}
        width={200}
        height={200}
        options={{ maintainAspectRatio: false }}
        legend={this.legendOptions(true)}
      />
    );
  };

  renderDoughnutStatus = () => {
    return (
      <Doughnut
        data={this.chartJsData(this.state.statusChartList)}
        width={200}
        height={200}
        options={{ maintainAspectRatio: false }}
        legend={this.legendOptions(true)}
      />
    );
  };

  toggleMessageModal = () => {
    return new Promise((resolve, reject) => {
      let mainState = this.state;

      mainState.openModalMessage = !mainState.openModalMessage;

      this.setState(mainState, resolve(true));
    });
  };

  setStudentIdAndOpenMessage = (id, name) => {
    let mainState = this.state;

    mainState.studentId = id;
    mainState.studentName = name;

    this.setState(mainState, () => {
      this.toggleMessageModal();
    });
  };

  toggleStudentClassViewModal = (countryId, countryName) => {
    return new Promise((resolve, reject) => {
      let mainState = this.state;

      mainState.openStudentClassViewModal =
        !mainState.openStudentClassViewModal;
      mainState.selectCountryIdForStudentClassViewModal = countryId;
      mainState.selectCountryNameForStudentClassViewModal = countryName;

      this.setState(mainState, resolve(true));
    });
  };

  toggleStudentClassGroupModal = (classGroup) => {
    return new Promise((resolve, reject) => {
      this.setState(
        {
          openStudentClassGroupModal: !this.state.openStudentClassGroupModal,
          classGroupForStudentClassGroupModal: classGroup,
        },
        resolve(true)
      );
    });
  };

  toggleStudentModal = () => {
    return new Promise((resolve, reject) => {
      let mainState = this.state;

      mainState.openStudentModal = !mainState.openStudentModal;

      this.setState(mainState, resolve(true));
    });
  };

  setStudentIdAndOpenModal = (id) => {
    let mainState = this.state;

    mainState.studentId = id;

    this.setState(mainState, () => {
      this.toggleStudentModal();
    });
  };

  dataChart = {
    data: () => {
      return {
        labels: [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ],
        datasets: this.state.enrollmentChartList.map((chart, index) => ({
          label: chart.name,
          data: chart.dataChartList,
          fill: false,
          borderColor: this.campusColor[index],
          backgroundColor: "transparent",
          pointBorderColor: this.campusColor[index],
          pointRadius: 4,
          pointHoverRadius: 4,
          pointBorderWidth: 8,
        })),
      };
    },
  };

  _refreshDashboard = () => {
    return new Promise((resolve, reject) => {
      axios.get("dashboard").then((response) => {
        let mainState = this.state;

        mainState.totalStudents = response.data.data.totalStudents;
        mainState.totalI20EndDate = response.data.data.totalI20EndDate;
        mainState.totalConsecutiveAbsences =
          response.data.data.total3ConsecutiveAbsences;
        mainState.totalFullPresent = response.data.data.totalFullPresent;
        mainState.totalAvailableSeats = Math.max(
          response.data.data.totalAvailableSeats,
          0
        );

        mainState.studentsByCountry = [];
        response.data.data.studentsByCountry.map((item, index) =>
          mainState.studentsByCountry.push({
            name: item.name.toLowerCase(),
            percent: item.percent.toFixed(2),
            total: item.total,
            countryName: myData.find((x) => x.value === item.name).label,
          })
        );

        mainState.birthdaysOfWeek = [];
        response.data.data.birthdaysOfWeek.map((student, index) =>
          mainState.birthdaysOfWeek.push({
            id: student.id,
            name: student.name,
            campusName: student.campusName,
            dateOfBirth: FormatMonthYearDateList(student.dateOfBirth),
            isToday:
              dateFns.format(dateFns.parseISO(student.dateOfBirth), "MM/dd") ===
              dateFns.format(new Date(), "MM/dd"),
          })
        );

        mainState.teacherClasses = [];
        response.data.data.tables.classeList.map((classe, index) =>
          mainState.teacherClasses.push({
            id: classe.id,
            code: classe.code,
            name: classe.name,
            totalPendencies: classe.totalPendencies,
            semesterStartDate: classe.semester.startDate,
            semesterEndDate: classe.semester.endDate,
            totalPoints: classe.totalPoints,
            days: classe.days,
          })
        );

        mainState.classGroups = [];
        response.data.data.classGroups.map((classGroup, index) =>
          mainState.classGroups.push({
            name: classGroup.name,
            total: classGroup.total,
            campusName: classGroup.campusName,
            level: classGroup.level,
          })
        );

        mainState.campusChartList = [];
        response.data.data.campusChartList.map((campus, index) =>
          mainState.campusChartList.push({
            name: campus.name
              .replace("Salt Lake in Person", "SLC")
              .replace("Orem In Person", "Orem")
              .replace("Online Combined Campuses", "Online"),
            total: campus.total,
          })
        );

        mainState.periodChartList = [];
        response.data.data.periodChartList.map((period, index) =>
          mainState.periodChartList.push({
            name: period.name,
            total: period.total,
          })
        );

        mainState.levelChartList = [];
        response.data.data.levelChartList.map((level, index) =>
          mainState.levelChartList.push({
            name: level.name
              .replace("Program", "")
              .trim()
              .replace("Community English", "Community"),
            total: level.total,
          })
        );

        mainState.statusChartList = [];
        response.data.data.statusChartList.map((status, index) =>
          mainState.statusChartList.push({
            name: status.name,
            total: status.total,
          })
        );

        mainState.lessonPlanChartList = [];
        response.data.data.lessonPlanChartList.map((lessonPlan, index) =>
          mainState.lessonPlanChartList.push({
            name: lessonPlan.name,
            total: lessonPlan.total,
          })
        );

        mainState.blockedStudents = [];
        response.data.data.blockedStudents.map((bs, index) =>
          mainState.blockedStudents.push({
            name: bs.name,
            classCode: bs.classCode,
            createdDate: dateFns.format(
              dateFns.parseISO(bs.createdDate),
              "MM/dd/yyyy hh:mm:ss aa"
            ),
          })
        );

        mainState.enrolledWithBalance = response.data.data.enrolledWithBalance;
        mainState.overdueInvoices = response.data.data.overdueInvoices;
        mainState.nonEnrolledInvoices = response.data.data.nonEnrolledInvoices;
        mainState.totalBlockedStudents =
          response.data.data.totalBlockedStudents;
        mainState.totalEnrolledStudents =
          response.data.data.totalEnrolledStudents;

        this.setState(mainState, resolve(true));
      });
    });
  };

  refreshAll = () => {
    this._refreshDashboard();
  };

  async componentDidMount() {
    let semesterId = JSON.parse(localStorage.getItem("semesterId"));

    if (!semesterId) {
      let currentSemesterId = this.props.tables.semesters.find(
        (x) => x.current === true
      ).id;

      if (currentSemesterId) {
        localStorage.setItem("semesterId", JSON.stringify(currentSemesterId));
      }

      this._refreshDashboard();
    } else {
      this._refreshDashboard();
    }

    this.timer = setInterval(() => {
      this._refreshDashboard();
    }, 300000);
  }

  currentSemesterSelected = () => {
    let localStorageSelected = JSON.parse(localStorage.getItem("semesterId"));

    if (localStorageSelected) {
      let currentSemesterId = this.props.tables.semesters.find(
        (x) => x.current === true
      ).id;

      return localStorageSelected == currentSemesterId;
    }

    return false;
  };

  componentWillUnmount() {
    clearInterval(this.timer);
  }

  getMainState = () => {
    return {
      totalStudents: "-",
      totalEnrolledStudents: "-",
      enrolledWithBalance: 0,
      totalBlockedStudents: 0,
      overdueInvoices: 0,
      nonEnrolledInvoices: 0,
      totalI20EndDate: "-",
      totalAvailableSeats: 0,
      totalConsecutiveAbsences: "-",
      totalFullPresent: "-",
      enrollmentChartList: [
        {
          label: "",
          data: [],
          fill: false,
          borderColor: "#fbc658",
          backgroundColor: "transparent",
          pointBorderColor: "#fbc658",
          pointRadius: 4,
          pointHoverRadius: 4,
          pointBorderWidth: 8,
        },
      ],
      studentsByCountry: [],
      classGroups: [],
      birthdaysOfWeek: [],
      openRenewI20Panel: false,
      openConsecutiveAbsencesPanel: false,
      openFullPresentPanel: false,
      openStudentModal: false,
      studentId: "",
      studentName: "",
      openAvailableSeatsPanel: false,
      openDashboardTeacherModal: false,
      selectCountryIdForStudentClassViewModal: "",
      selectCountryNameForStudentClassViewModal: "",
      classGroupForStudentClassGroupModal: "",
      selectClassForDashboardTeacherModal: {},
      openStudentClassViewModal: false,
      openStudentClassGroupModal: false,
      teacherClasses: [],
      collapseClassesCardIsOpen: false,
      collapseBirthdayCardIsOpen: false,
      collapseCountriesCardIsOpen: false,
      openAlert: true,
      campusChartList: [],
      periodChartList: [],
      levelChartList: [],
      statusChartList: [],
      lessonPlanChartList: [],
      blockedStudents: [],
    };
  };

  campusColor = ["#fcc468", "#51BCDA", "#ef8157"];

  toggleRenewI20Modal = () => {
    return new Promise((resolve, reject) => {
      let mainState = this.state;
      mainState.openRenewI20Panel = !mainState.openRenewI20Panel;
      this.setState(mainState, resolve(true));
    });
  };

  toggleStudentsFullPresentModal = () => {
    return new Promise((resolve, reject) => {
      let mainState = this.state;
      mainState.openFullPresentPanel = !mainState.openFullPresentPanel;
      this.setState(mainState, resolve(true));
    });
  };

  toggleConsecutiveAbsencesModal = () => {
    return new Promise((resolve, reject) => {
      let mainState = this.state;
      mainState.openConsecutiveAbsencesPanel =
        !mainState.openConsecutiveAbsencesPanel;
      this.setState(mainState, resolve(true));
    });
  };

  toggleAvailableSeatsModal = () => {
    return new Promise((resolve, reject) => {
      let mainState = this.state;
      mainState.openAvailableSeatsPanel = !mainState.openAvailableSeatsPanel;
      this.setState(mainState, resolve(true));
    });
  };

  toggleDashboardTeacherModal = (classe) => {
    return new Promise((resolve, reject) => {
      let mainState = this.state;

      mainState.openDashboardTeacherModal =
        !mainState.openDashboardTeacherModal;
      mainState.selectClassForDashboardTeacherModal = classe;

      this.setState(mainState, resolve(true));
    });
  };

  toggleCollapseClassesCard = (classe) => {
    return new Promise((resolve, reject) => {
      let mainState = this.state;

      mainState.collapseClassesCardIsOpen =
        !mainState.collapseClassesCardIsOpen;

      this.setState(mainState, resolve(true));
    });
  };

  toggleCollapseBirthdayCard = (classe) => {
    return new Promise((resolve, reject) => {
      let mainState = this.state;

      mainState.collapseBirthdayCardIsOpen =
        !mainState.collapseBirthdayCardIsOpen;

      this.setState(mainState, resolve(true));
    });
  };

  toggleCollapseCountriesCard = (classe) => {
    return new Promise((resolve, reject) => {
      let mainState = this.state;

      mainState.collapseCountriesCardIsOpen =
        !mainState.collapseCountriesCardIsOpen;

      this.setState(mainState, resolve(true));
    });
  };

  downloadCSVClasses = () => {
    let classesCsv = [];

    this.state.classGroups.forEach((item) => {
      classesCsv.push({
        Campus: item.campusName,
        Level: item.level,
        Name: item.name,
        Total: item.total,
      });
    });

    this.downloadCSVFromJson("Classes.csv", classesCsv);
  };

  downloadCSVFromJson = (filename, arrayOfJson) => {
    const replacer = (key, value) => (value === null ? "" : value);
    const header = Object.keys(arrayOfJson[0]);
    let csv = arrayOfJson.map((row) =>
      header
        .map((fieldName) => JSON.stringify(row[fieldName], replacer))
        .join(";")
    );
    csv.unshift(header.join(";"));
    csv = csv.join("\r\n");

    var link = document.createElement("a");
    link.setAttribute(
      "href",
      "data:text/csv;charset=utf-8,%EF%BB%BF" + encodeURIComponent(csv)
    );
    link.setAttribute("download", filename);
    link.style.visibility = "hidden";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
}

export default Dashboard;
