/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";

import {
  Card,
  CardHeader,
  CardBody,
  Table,
  Row,
  Col,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  FormGroup,
  Input,
  Label,
  ModalFooter,
  ButtonGroup,
} from "reactstrap";
import axios from "axios";
import Pagination from "components/Pagination/Pagination.js";
import Select from "react-select";
import confirm from "reactstrap-confirm";
import { editMode, FormatDate, FormatFullDateList } from "helpers/Utils.js";
import * as dateFns from "date-fns";
import DatePicker from "react-datepicker";
import Can from "../components/Can/Can";
import { authenticationService } from "services/Authentication.service.js";

class Attendance extends React.Component {
  constructor(props) {
    super(props);
    this.state = this.getInitialState();
  }

  render() {
    const { currentUser } = this.state.mainState;

    let attendances = this.state.mainState.attendances.map((attendance) => {
      return (
        <tr key={attendance.id}>
          <td>
            <a
              onClick={this.openModalAttendance.bind(
                this,
                editMode.EDIT,
                attendance.id
              )}
              href="#"
            >
              {attendance.classCode}
            </a>
          </td>

          <td>{attendance.campusName}</td>
          <td>{attendance.date}</td>

          {currentUser !== null && (
            <Can
              feature="Attendance_Delete"
              yes={() => (
                <td>
                  <Button
                    color="danger"
                    size="sm"
                    onClick={this.deleteAttendance.bind(this, attendance.id)}
                  >
                    Delete
                  </Button>
                </td>
              )}
            ></Can>
          )}
        </tr>
      );
    });

    let attendanceStudents =
      this.state.modalState.Attendance.attendanceStudents.map(
        (attendanceStudent) => {
          return (
            <tr key={attendanceStudent.StudentId}>
              <td>{attendanceStudent.StudentName}</td>
              <td>
                <ButtonGroup>
                  <Button
                    color="success"
                    onClick={this.handleStatusChange.bind(
                      this,
                      "P",
                      attendanceStudent.StudentId
                    )}
                    active={attendanceStudent.Status === "P"}
                    style={{
                      opacity: attendanceStudent.Status === "P" ? "1" : "0.3",
                    }}
                  >
                    Present
                  </Button>
                  <Button
                    color="warning"
                    onClick={this.handleStatusChange.bind(
                      this,
                      "T",
                      attendanceStudent.StudentId
                    )}
                    active={attendanceStudent.Status === "T"}
                    style={{
                      opacity: attendanceStudent.Status === "T" ? "1" : "0.3",
                    }}
                  >
                    Tardy
                  </Button>
                  <Button
                    color="danger"
                    onClick={this.handleStatusChange.bind(
                      this,
                      "A",
                      attendanceStudent.StudentId
                    )}
                    active={attendanceStudent.Status === "A"}
                    style={{
                      opacity: attendanceStudent.Status === "A" ? "1" : "0.3",
                    }}
                  >
                    Absent
                  </Button>
                  <Button
                    color="text-gray"
                    onClick={this.handleStatusChange.bind(
                      this,
                      "NT",
                      attendanceStudent.StudentId
                    )}
                    active={attendanceStudent.Status === "NT"}
                    style={{
                      opacity:
                        attendanceStudent.Status === "NT" ? "0.6" : "0.3",
                    }}
                  >
                    n.y.t
                  </Button>
                </ButtonGroup>
              </td>
              <td>
                <Input
                  type="text"
                  name="comment"
                  autoComplete="nope"
                  value={attendanceStudent.Comment}
                  onChange={this.handleCommentInput.bind(
                    this,
                    attendanceStudent.StudentId
                  )}
                />
              </td>
            </tr>
          );
        }
      );

    return (
      <>
        <div className="content">
          <Row>
            <Col md="12">
              <Card>
                <CardHeader>
                  <Row form>
                    <FormGroup>
                      <Label>&nbsp;</Label>
                      <Button
                        style={{ margin: "0" }}
                        color="primary"
                        onClick={this.openModalAttendance.bind(
                          this,
                          editMode.INSERT
                        )}
                      >
                        Add attendance
                      </Button>
                    </FormGroup>

                    <FormGroup style={{ marginLeft: "5px" }}>
                      <Label>Start Date</Label>

                      <DatePicker
                        selected={this.state.mainState.StartDateSearch}
                        onChange={this.handleSearchDateInput.bind(
                          this,
                          "StartDateSearch"
                        )}
                        name="StartDateSearch"
                        dateFormat="MM/dd/yyyy"
                      />
                    </FormGroup>

                    <FormGroup style={{ marginLeft: "5px" }}>
                      <Label>End Date</Label>
                      <DatePicker
                        selected={this.state.mainState.EndDateSearch}
                        onChange={this.handleSearchDateInput.bind(
                          this,
                          "EndDateSearch"
                        )}
                        name="EndDateSearch"
                        dateFormat="MM/dd/yyyy"
                      />
                    </FormGroup>

                    <Col md={2}>
                      <FormGroup>
                        <Label for="studentClass">Level</Label>
                        <Select
                          className={"form-group"}
                          value={
                            this.state.mainState.courseId &&
                            this.state.mainState.courses.find(
                              (x) => x.value === this.state.mainState.courseId
                            )
                          }
                          onChange={this.handleCourseChange}
                          options={this.state.mainState.courses}
                        />
                      </FormGroup>
                    </Col>

                    <Col md={2}>
                      <FormGroup>
                        <Label for="studentClass">Period</Label>
                        <Select
                          className={"form-group"}
                          value={
                            this.state.mainState.periodId &&
                            this.periodOptions.find(
                              (x) => x.value === this.state.mainState.periodId
                            )
                          }
                          onChange={this.handlePeriodChange}
                          options={this.periodOptions}
                          isDisabled={this.state.mainState.courseId === "all"}
                        />
                      </FormGroup>
                    </Col>

                    <Col md={2}>
                      <FormGroup>
                        <Label for="studentClass">Subject</Label>
                        <Select
                          className={"form-group"}
                          value={
                            this.state.mainState.classId &&
                            this.state.mainState.classes.find(
                              (x) => x.value === this.state.mainState.classId
                            )
                          }
                          onChange={this.handleClassMainStateChange}
                          options={this.state.mainState.classes}
                          isDisabled={
                            this.state.mainState.courseId === "all" ||
                            this.state.mainState.periodId === "all"
                          }
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  {/* <CardTitle tag="h4">Campuses</CardTitle> */}
                </CardHeader>

                <CardBody>
                  <Table>
                    <thead>
                      <tr>
                        <th>Subject</th>
                        <th>Campus</th>
                        <th>Date</th>

                        {currentUser !== null && (
                          <Can
                            feature="Attendance_Delete"
                            yes={() => <th>Actions</th>}
                          ></Can>
                        )}
                      </tr>
                    </thead>

                    <tbody>{attendances}</tbody>
                  </Table>

                  <Pagination
                    activePage={this.state.mainState.currentPage}
                    totalItemsCount={this.state.mainState.totalResults}
                    onChange={this._refreshAttendances.bind(this)}
                  />

                  <Modal
                    isOpen={this.state.mainState.openModal}
                    toggle={this.toggleNewAttendanceModal.bind(this)}
                    centered={true}
                    autoFocus={true}
                    size={"lg"}
                    backdrop="static"
                    onClosed={this.onCloseModal}
                  >
                    <ModalHeader
                      toggle={this.toggleNewAttendanceModal.bind(this)}
                    >
                      {this.state.modalState.editMode === editMode.INSERT
                        ? "Add a new attendance"
                        : "Edit an attendance"}
                    </ModalHeader>
                    <ModalBody>
                      <Row form>
                        <Col md={4}>
                          <FormGroup>
                            <Label for="attendanceClass">Subject</Label>
                            <Select
                              className={`form-group ${this.errorClass(
                                this.state.modalState.Attendance.ClasseId
                                  .ErrorMsg
                              )}`}
                              value={
                                this.state.modalState.Attendance.ClasseId
                                  .Value &&
                                this.state.modalState.classes.find(
                                  (x) =>
                                    x.value ===
                                    this.state.modalState.Attendance.ClasseId
                                      .Value
                                )
                              }
                              onChange={this.handleClasseChange}
                              options={this.state.modalState.classes}
                              isDisabled={
                                this.state.modalState.editMode === editMode.EDIT
                              }
                            />
                            <span className="text-danger">
                              {
                                this.state.modalState.Attendance.ClasseId
                                  .ErrorMsg
                              }
                            </span>
                          </FormGroup>
                        </Col>
                        <Col md={4}>
                          <FormGroup>
                            <Label for="attendanceDate">Date</Label>
                            <Input
                              className={`form-group ${this.errorClass(
                                this.state.modalState.Attendance.Date.ErrorMsg
                              )}`}
                              type="date"
                              name="Date"
                              id="attendanceDate"
                              autoComplete="nope"
                              value={
                                this.state.modalState.Attendance.Date.Value
                              }
                              onChange={this.handleUserInput}
                            />
                            <span className="text-danger">
                              {this.state.modalState.Attendance.Date.ErrorMsg}
                            </span>
                          </FormGroup>
                        </Col>
                      </Row>

                      <Row form>
                        <Table className="table table-striped">
                          <thead>
                            <tr>
                              {/* <th>#StudentId</th> */}
                              <th>Name</th>
                              <th>Status</th>
                              <th>Comment</th>
                            </tr>
                          </thead>

                          <tbody>{attendanceStudents}</tbody>
                        </Table>
                      </Row>
                    </ModalBody>
                    <ModalFooter>
                      <Button
                        color="primary"
                        onClick={
                          this.state.modalState.editMode === editMode.INSERT
                            ? this.addAttendance.bind(this)
                            : this.updateAttendance.bind(this)
                        }
                        disabled={!this.state.modalState.formValid}
                      >
                        {this.state.modalState.editMode === editMode.INSERT
                          ? "Add"
                          : "Update"}
                      </Button>{" "}
                      <Button
                        color="secondary"
                        onClick={this.toggleNewAttendanceModal.bind(this)}
                      >
                        Cancel
                      </Button>
                    </ModalFooter>
                  </Modal>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </>
    );
  }

  handleStatusChange(status, id) {
    let { modalState } = this.state;

    const index = modalState.Attendance.attendanceStudents.findIndex(
      (x) => x.StudentId === id
    );

    const attendanceStudents = [...modalState.Attendance.attendanceStudents];

    const attendanceStudent = attendanceStudents[index];
    attendanceStudent.Status = status;

    this.setState({ modalState: modalState });
  }

  getInitialState = () => {
    const initialState = {
      mainState: this.getMainState(),
      modalState: this.getModalState(),
    };

    return initialState;
  };

  getMainState = () => {
    const mainState = {
      attendances: [],
      openModal: false,
      totalResults: 0,
      currentPage: 1,
      itemsPerPage: 10,
      alert: { visible: false, color: "", message: "" },
      StartDateSearch: dateFns.startOfWeek(new Date()),
      EndDateSearch: dateFns.endOfWeek(new Date()),
      courses: [],
      classes: [],
      courseId: "",
      periodId: "all",
      classId: "",
      currentUser: authenticationService.currentUserValue,
    };

    return mainState;
  };

  getEntity = () => {
    const entity = {
      Value: "",
      IsValid: false,
      ErrorMsg: "",
    };
    return entity;
  };

  getAttendance = () => {
    const attendance = {
      Id: this.getEntity(),
      Date: this.getEntity(),
      ClasseId: this.getEntity(),
      attendanceStudents: [],
    };
    return attendance;
  };

  getModalState = () => {
    const modalState = {
      Attendance: this.getAttendance(),
      editMode: "",
      cards: [1, 2],
      alert: { visible: false, color: "", message: "" },
      formValid: false,
      //value: "",
      classes: [],
    };

    return modalState;
  };

  resetModal = () => {
    return new Promise((resolve, reject) => {
      this.setState(
        {
          modalState: this.getModalState(),
        },
        resolve(true)
      );
    });
  };

  handleSearchDateInput = (name, date) => {
    if (date === null) date = new Date();

    let { mainState } = this.state;

    mainState[name] = date;
    mainState.currentPage = 1;

    this.setState({ mainState: mainState }, () => {
      this._refreshAttendances();
    });
  };

  validateField(fieldName, value) {
    let { modalState } = this.state;

    switch (fieldName) {
      case "Date":
        modalState.Attendance.Date.IsValid = value.length >= 1;
        modalState.Attendance.Date.ErrorMsg = modalState.Attendance.Date.IsValid
          ? ""
          : "Date is empty";
        break;
      case "ClasseId":
        modalState.Attendance.ClasseId.IsValid = value.length >= 1;
        modalState.Attendance.ClasseId.ErrorMsg = modalState.Attendance.ClasseId
          .IsValid
          ? ""
          : "Subject is empty";
        break;
      default:
        break;
    }

    this.setState(
      {
        modalState: modalState,
      },
      this.validateForm()
    );
  }

  validateForm() {
    let { modalState } = this.state;

    modalState.formValid =
      modalState.Attendance.Date.IsValid === true &&
      modalState.Attendance.ClasseId.IsValid === true;

    this.setState({
      modalState: modalState,
    });
  }

  handleUserInput = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    let { modalState } = this.state;
    modalState.Attendance[name].Value = value;

    this.setState({ modalState: modalState }, () => {
      this.validateField(name, value);
    });
  };

  handleCommentInput(id, e) {
    let { modalState } = this.state;

    const index = modalState.Attendance.attendanceStudents.findIndex(
      (x) => x.StudentId === id
    );

    const attendanceStudents = [...modalState.Attendance.attendanceStudents];

    const attendanceStudent = attendanceStudents[index];
    attendanceStudent.Comment = e.target.value;

    this.setState({ modalState: modalState });
  }

  handleCourseChange = (selectedOption) => {
    let mainState = this.state.mainState;

    mainState.courseId = selectedOption.value;
    mainState.classId = "";
    mainState.periodId = "all";
    mainState.currentPage = 1;

    this.setState({ mainState: mainState });

    this._refreshAttendances().then(() => {
      this.refreshClassesMainState();
    });
  };

  handlePeriodChange = (selectedOption) => {
    let mainState = this.state.mainState;

    mainState.periodId = selectedOption.value;
    mainState.classId = "";
    mainState.currentPage = 1;

    this.setState({ mainState: mainState });

    this._refreshAttendances().then(() => {
      this.refreshClassesMainState();
    });
  };

  componentDidMount() {
    this._refreshAttendances().then(() => {
      this.refreshCourses();
    });
  }

  refreshCourses = () => {
    return new Promise((resolve, reject) => {
      let mainState = this.state.mainState;

      mainState.courses = [];

      mainState.courses.push({
        value: "all",
        label: "All levels",
      });

      mainState.courseId = "all";

      this.props.tables.courses.map((course, index) =>
        mainState.courses.push({
          value: course.id,
          label: course.name,
        })
      );

      this.setState({ mainState: mainState }, resolve(true));
    });
  };

  refreshClassesMainState = () => {
    return new Promise((resolve, reject) => {
      let courseId = this.state.mainState.courseId;
      let periodId = this.state.mainState.periodId;

      if (periodId === "all") periodId = "";

      axios
        .get(
          "classes?courseId=" +
            courseId +
            "&period=" +
            periodId +
            "&page=1&pageSize=999999"
        )
        .then((response) => {
          let mainState = this.state.mainState;

          mainState.classes = [];

          mainState.classes.push({
            value: "all",
            label: "All subjects",
          });

          mainState.classId = "all";

          response.data.data.items.map((classe, index) =>
            mainState.classes.push({
              value: classe.id,
              label: classe.code,
            })
          );

          this.setState({ mainState: mainState }, resolve(true));
        });
    });
  };

  handleClassMainStateChange = (selectedOption) => {
    let mainState = this.state.mainState;

    mainState.classId = selectedOption.value;
    mainState.currentPage = 1;

    this.setState({ mainState: mainState }, () => {
      this._refreshAttendances();
    });
  };

  toggleNewAttendanceModal = () => {
    return new Promise((resolve, reject) => {
      let mainState = this.state.mainState;
      mainState.openModal = !mainState.openModal;
      this.setState(
        {
          mainState: mainState,
        },
        resolve(true)
      );
    });
  };

  addAttendance() {
    let { modalState } = this.state;

    let attendanceStudents = [];
    modalState.Attendance.attendanceStudents.map((attendanceStudent, index) =>
      attendanceStudents.push({
        Id: attendanceStudent.Id,
        Status: attendanceStudent.Status,
        Comment: attendanceStudent.Comment,
        Student: { Id: attendanceStudent.StudentId },
      })
    );

    let attendance = {
      Date: this.state.modalState.Attendance.Date.Value,
      Classe: { Id: this.state.modalState.Attendance.ClasseId.Value },
      AttendanceStudentViewModels: attendanceStudents,
    };

    axios.post("attendances", attendance).then((response) => {
      this.toggleNewAttendanceModal().then(() => {
        this._refreshAttendances().then(() => {
          this.resetModal();
        });
      });
    });
  }

  updateAttendance() {
    let { modalState } = this.state;

    let attendanceStudents = [];
    modalState.Attendance.attendanceStudents.map((attendanceStudent, index) =>
      attendanceStudents.push({
        Id: attendanceStudent.Id,
        Status: attendanceStudent.Status,
        Comment: attendanceStudent.Comment,
        Student: { Id: attendanceStudent.StudentId },
      })
    );

    let attendance = {
      Id: this.state.modalState.Attendance.Id.Value,
      Date: this.state.modalState.Attendance.Date.Value,
      Classe: { Id: this.state.modalState.Attendance.ClasseId.Value },
      AttendanceStudentViewModels: attendanceStudents,
    };

    axios.put("attendances", attendance).then((response) => {
      this.resetModal().then(() => {
        this.toggleNewAttendanceModal().then(() => {
          this._refreshAttendances();
        });
      });
    });
  }

  refreshClasses = () => {
    return new Promise((resolve, reject) => {
      axios.get("classes?page=1&pageSize=999999").then((response) => {
        let { modalState } = this.state;

        response.data.data.items.map((classe, index) =>
          modalState.classes.push({
            value: classe.id,
            label: classe.code,
          })
        );

        this.setState(
          {
            modalState: modalState,
          },
          resolve(true)
        );
      });
    });
  };

  openModalAttendance(mode, id) {
    let { modalState } = this.state;
    modalState.editMode = mode;

    this.setState({ modalState: modalState });

    this.refreshClasses().then(() => {
      if (mode === editMode.INSERT) {
        this.toggleNewAttendanceModal().then(() => {
          let newDate = new Date();

          modalState.Attendance.Date.Value = dateFns.format(
            newDate,
            "yyyy-MM-dd"
          );
          modalState.Attendance.Date.IsValid = true;

          this.setState({
            modalState: modalState,
          });
        });
      } else {
        axios
          .get("attendances/byid?id=" + id + "&fillStudents=true")
          .then((response) => {
            modalState.Attendance.Id.Value = response.data.data.id;

            modalState.Attendance.Date.Value = FormatDate(
              response.data.data.date
            );
            modalState.Attendance.Date.IsValid = true;

            modalState.Attendance.ClasseId.Value = response.data.data.classe.id;
            modalState.Attendance.ClasseId.IsValid = true;

            response.data.data.attendanceStudentViewModels.map(
              (student, index) =>
                modalState.Attendance.attendanceStudents.push({
                  Id: student.id,
                  StudentId: student.student.id,
                  StudentName: student.student.name,
                  Status: student.status,
                  Comment: student.comment,
                })
            );

            this.validateForm();

            this.setState(
              {
                modalState: modalState,
              },
              () => {
                this.toggleNewAttendanceModal();
              }
            );
          });
      }
    });
  }

  async deleteAttendance(id) {
    let result = await confirm({
      title: <>Warning</>,
      message: "Are you sure you wish to delete this item?",
    });

    if (result) {
      axios.delete("attendances/" + id).then(() => {
        this._refreshAttendances();
      });
    }
  }

  _refreshAttendances = (pageNumber) => {
    return new Promise((resolve, reject) => {
      let page = pageNumber || 1;
      let itemsPerPage = this.state.mainState.itemsPerPage;
      let startDate = dateFns.format(
        this.state.mainState.StartDateSearch,
        "yyyy-MM-dd"
      );
      let endDate = dateFns.format(
        this.state.mainState.EndDateSearch,
        "yyyy-MM-dd"
      );
      let courseId = this.state.mainState.courseId;
      let periodId = this.state.mainState.periodId;
      let classId = this.state.mainState.classId;

      if (periodId === "all") periodId = "";

      axios
        .get(
          "attendances?" +
            "startDate=" +
            startDate +
            "&endDate=" +
            endDate +
            "&courseId=" +
            courseId +
            "&period=" +
            periodId +
            "&classId=" +
            classId +
            "&page=" +
            page +
            "&pageSize=" +
            itemsPerPage
        )
        .then((response) => {
          let { mainState } = this.state;

          mainState.attendances = [];
          response.data.data.items.map((attendance, index) =>
            mainState.attendances.push({
              id: attendance.id,
              date: FormatFullDateList(attendance.date),
              classId: attendance.classe.id,
              classCode: attendance.classe.code,
              campusName: attendance.classe.campus.name,
            })
          );

          mainState.currentPage = pageNumber;
          mainState.totalResults = response.data.data.totalCount;

          this.setState({ mainState: mainState }, resolve(true));
        });
    });
  };

  onCloseModal = () => {
    this.resetModal();
  };

  errorClass(error) {
    return error.length === 0 ? "" : "is-invalid";
  }

  setCurrentPage(pageNumber) {
    let { mainState } = this.state;
    mainState.currentPage = pageNumber;
    this.setState({ mainState: mainState });
  }

  fillStudents = () => {
    let { modalState } = this.state;

    if (modalState.Attendance.ClasseId.Value !== "") {
      axios
        .get(
          "classes/byid?id=" +
            modalState.Attendance.ClasseId.Value +
            "&fillStudents=true"
        )
        .then((response) => {
          let { modalState } = this.state;

          modalState.Attendance.attendanceStudents = [];
          response.data.data.students.map((student, index) =>
            modalState.Attendance.attendanceStudents.push({
              // id: uuid.v4(),
              StudentId: student.id,
              StudentName: student.name,
              Status: "NT",
              Comment: "",
            })
          );

          this.setState({
            modalState: modalState,
          });
        });
    }
  };

  handleClasseChange = (selectedOption) => {
    let { modalState } = this.state;

    modalState.Attendance.ClasseId.Value = selectedOption.value;

    this.setState(
      { modalState: modalState },
      () => {
        this.validateField("ClasseId", selectedOption.value);
      },
      this.fillStudents()
    );
  };

  periodOptions = [
    { value: "all", label: "All periods" },
    { value: "M", label: "Morning" },
    { value: "A", label: "Afternoon" },
    { value: "E", label: "Evening" },
    { value: "MT", label: "Mon/Tue" },
    { value: "WT", label: "Wed/Thu" },
    { value: "HW", label: "Wed" },
  ];
}

export default Attendance;
