/* eslint-disable array-callback-return */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";

import {
  Card,
  CardHeader,
  CardBody,
  Table,
  Row,
  Col,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  FormGroup,
  Input,
  Label,
  ModalFooter,
  CustomInput,
  ListGroup,
  ListGroupItem,
} from "reactstrap";
import axios from "axios";
import Pagination from "components/Pagination/Pagination.js";
import Select from "react-select";
import confirm from "reactstrap-confirm";
import { editMode, FormatFullDateList } from "helpers/Utils.js";
import * as dateFns from "date-fns";
import DatePicker from "react-datepicker";
import Can from "../components/Can/Can";
import { authenticationService } from "services/Authentication.service.js";

class Assignment extends React.Component {
  constructor(props) {
    super(props);
    this.state = this.getInitialState();
  }

  render() {
    const { currentUser } = this.state.mainState;

    let assignments = this.state.mainState.assignments.map((assignment) => {
      return (
        <tr key={assignment.id}>
          <td>
            <a
              onClick={this.openModalAssignment.bind(
                this,
                editMode.EDIT,
                assignment.id
              )}
              href="#"
            >
              {assignment.classCode}
            </a>
          </td>

          <td className="hideColumn">{assignment.campusName}</td>
          <td>{assignment.date}</td>

          <td>{assignment.name}</td>

          {currentUser !== null && (
            <Can
              feature="Assignment_Delete"
              yes={() => (
                <td>
                  <Button
                    color="danger"
                    size="sm"
                    onClick={this.deleteAssignment.bind(this, assignment.id)}
                  >
                    Delete
                  </Button>
                </td>
              )}
            ></Can>
          )}
        </tr>
      );
    });

    let subjects =
      this.state.modalState.selectedClassGroupForInsert.subjects.map(
        (subject, index) => {
          return (
            <ListGroupItem key={index}>
              <CustomInput
                type="switch"
                id={subject.id}
                label={subject.code}
                checked={subject.checked}
                onChange={this.onSwitchSubjectChange}
              />
            </ListGroupItem>
          );
        }
      );

    return (
      <>
        <div className="content">
          <Row>
            <Col md="12">
              <Card>
                <CardHeader>
                  <Row form>
                    <FormGroup>
                      <Label>&nbsp;</Label>
                      <Button
                        style={{ margin: "0" }}
                        color="primary"
                        onClick={this.openModalAssignment.bind(
                          this,
                          editMode.INSERT
                        )}
                      >
                        Add assignment
                      </Button>
                    </FormGroup>

                    <FormGroup style={{ marginLeft: "5px" }}>
                      <Label>Start Date</Label>

                      <DatePicker
                        selected={this.state.mainState.StartDateSearch}
                        onChange={this.handleSearchDateInput.bind(
                          this,
                          "StartDateSearch"
                        )}
                        name="StartDateSearch"
                        dateFormat="MM/dd/yyyy"
                      />
                    </FormGroup>

                    <FormGroup style={{ marginLeft: "5px" }}>
                      <Label>End Date</Label>
                      <DatePicker
                        selected={this.state.mainState.EndDateSearch}
                        onChange={this.handleSearchDateInput.bind(
                          this,
                          "EndDateSearch"
                        )}
                        name="EndDateSearch"
                        dateFormat="MM/dd/yyyy"
                      />
                    </FormGroup>
                  </Row>
                  <Row form>
                    <Col md={2}>
                      <FormGroup>
                        <Label for="studentClass">Level</Label>
                        <Select
                          className={"form-group"}
                          value={
                            this.state.mainState.courseId &&
                            this.state.mainState.courses.find(
                              (x) => x.value === this.state.mainState.courseId
                            )
                          }
                          onChange={this.handleCourseChange}
                          options={this.state.mainState.courses}
                        />
                      </FormGroup>
                    </Col>

                    <Col md={2}>
                      <FormGroup>
                        <Label for="studentClass">Period</Label>
                        <Select
                          className={"form-group"}
                          value={
                            this.state.mainState.periodId &&
                            this.periodOptions.find(
                              (x) => x.value === this.state.mainState.periodId
                            )
                          }
                          onChange={this.handlePeriodChange}
                          options={this.periodOptions}
                        />
                      </FormGroup>
                    </Col>

                    <Col md={2}>
                      <FormGroup>
                        <Label for="studentClass">Subject</Label>
                        <Select
                          className={"form-group"}
                          value={
                            this.state.mainState.classId &&
                            this.state.mainState.classes.find(
                              (x) => x.value === this.state.mainState.classId
                            )
                          }
                          onChange={this.handleClassMainStateChange}
                          options={this.state.mainState.classes}
                        />
                      </FormGroup>
                    </Col>

                    <Col md={2}>
                      <FormGroup>
                        <Label for="studentClass">Class</Label>
                        <Select
                          className={"form-group"}
                          value={
                            this.state.mainState.classGroupId &&
                            this.state.mainState.classGroups.find(
                              (x) =>
                                x.value === this.state.mainState.classGroupId
                            )
                          }
                          onChange={this.handleClassGroupMainStateChange}
                          options={this.state.mainState.classGroups}
                        />
                      </FormGroup>
                    </Col>

                    <Col md={2}>
                      <FormGroup>
                        <Label for="studentClass">Assignment Type</Label>
                        <Select
                          className={"form-group"}
                          value={
                            this.state.mainState.assignmentTypeId &&
                            this.state.mainState.assignmentTypes.find(
                              (x) =>
                                x.value ===
                                this.state.mainState.assignmentTypeId
                            )
                          }
                          onChange={this.handleAssignmentTypeMainStateChange}
                          options={this.state.mainState.assignmentTypes}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  {/* <CardTitle tag="h4">Campuses</CardTitle> */}
                </CardHeader>

                <CardBody>
                  <Table>
                    <thead>
                      <tr>
                        <th>Subject</th>
                        <th className="hideColumn">Campus</th>
                        <th>Date</th>
                        <th>Name</th>

                        {currentUser !== null && (
                          <Can
                            feature="Assignment_Delete"
                            yes={() => <th>Actions</th>}
                          ></Can>
                        )}
                      </tr>
                    </thead>

                    <tbody>{assignments}</tbody>
                  </Table>

                  <Pagination
                    activePage={this.state.mainState.currentPage}
                    totalItemsCount={this.state.mainState.totalResults}
                    onChange={this._refreshAssignments.bind(this)}
                  />

                  <Modal
                    isOpen={this.state.mainState.openModal}
                    toggle={this.toggleNewAssignmentModal.bind(this)}
                    centered={true}
                    autoFocus={true}
                    size={"lg"}
                    backdrop="static"
                    onClosed={this.onCloseModal}
                  >
                    <ModalHeader
                      toggle={this.toggleNewAssignmentModal.bind(this)}
                    >
                      {this.state.modalState.editMode === editMode.INSERT
                        ? "Add a new assignment"
                        : "Edit an assignment"}
                    </ModalHeader>
                    <ModalBody>
                      <Row form>
                        {this.state.modalState.editMode === editMode.INSERT && (
                          <Col md={4}>
                            <FormGroup>
                              <Label for="assignmentClass">Class</Label>
                              <Select
                                value={
                                  this.state.modalState
                                    .selectedClassGroupForInsert.value &&
                                  this.state.modalState.classGroups.find(
                                    (x) =>
                                      x.value ===
                                      this.state.modalState
                                        .selectedClassGroupForInsert.value
                                  )
                                }
                                onChange={this.handleClassGroupChange}
                                options={this.state.modalState.classGroups}
                              />
                            </FormGroup>
                          </Col>
                        )}

                        {this.state.modalState.editMode === editMode.EDIT && (
                          <Col md={4}>
                            <FormGroup>
                              <Label for="assignmentClass">Subject</Label>
                              <Select
                                className={`form-group ${this.errorClass(
                                  this.state.modalState.Assignment.ClasseId
                                    .ErrorMsg
                                )}`}
                                value={
                                  this.state.modalState.Assignment.ClasseId
                                    .Value &&
                                  this.state.modalState.classes.find(
                                    (x) =>
                                      x.value ===
                                      this.state.modalState.Assignment.ClasseId
                                        .Value
                                  )
                                }
                                onChange={this.handleClasseChange}
                                options={this.state.modalState.classes}
                                isDisabled={true}
                              />
                              <span className="text-danger">
                                {
                                  this.state.modalState.Assignment.ClasseId
                                    .ErrorMsg
                                }
                              </span>
                            </FormGroup>
                          </Col>
                        )}

                        <Col md={4}>
                          <FormGroup>
                            <Label for="assignmentDate">Date</Label>

                            <DatePicker
                              className={`form-group ${this.errorClass(
                                this.state.modalState.Assignment.Date.ErrorMsg
                              )}`}
                              selected={
                                this.state.modalState.Assignment.Date.Value
                              }
                              onChange={this.handleDateInput.bind(this, "Date")}
                              name="Date"
                              dateFormat="MM/dd/yyyy"
                            />

                            <span className="text-danger">
                              {this.state.modalState.Assignment.Date.ErrorMsg}
                            </span>
                          </FormGroup>
                        </Col>
                        <Col md={2}>
                          <FormGroup>
                            <Label for="assignmentMaxGrade">Max Grade</Label>
                            <Input
                              className={`form-group ${this.errorClass(
                                this.state.modalState.Assignment.MaxGrade
                                  .ErrorMsg
                              )}`}
                              type="number"
                              name="MaxGrade"
                              id="assignmentMaxGrade"
                              autoComplete="nope"
                              value={
                                this.state.modalState.Assignment.MaxGrade.Value
                              }
                              onChange={this.handleUserInput}
                              // onBlur={this.maxGradeOnBlur}
                              onFocus={this.handleFocus}
                            />
                            <span className="text-danger">
                              {
                                this.state.modalState.Assignment.MaxGrade
                                  .ErrorMsg
                              }
                            </span>
                          </FormGroup>
                        </Col>
                      </Row>

                      <Row form>
                        <Col md={4}>
                          <FormGroup>
                            <Label for="assignmentType">Type</Label>
                            <Select
                              id="assignmentType"
                              className={`form-group ${this.errorClass(
                                this.state.modalState.Assignment
                                  .AssignmentTypeId.ErrorMsg
                              )}`}
                              value={
                                this.state.modalState.Assignment
                                  .AssignmentTypeId.Value &&
                                this.state.modalState.assignmentTypes.find(
                                  (x) =>
                                    x.value ===
                                    this.state.modalState.Assignment
                                      .AssignmentTypeId.Value
                                )
                              }
                              onChange={this.handleAssignmentTypeChange}
                              options={this.state.modalState.assignmentTypes}
                            />
                            <span className="text-danger">
                              {
                                this.state.modalState.Assignment
                                  .AssignmentTypeId.ErrorMsg
                              }
                            </span>
                          </FormGroup>
                        </Col>
                        <Col md={8}>
                          <FormGroup>
                            <Label for="assignmentName">Name</Label>
                            <Input
                              className={`form-group ${this.errorClass(
                                this.state.modalState.Assignment.Name.ErrorMsg
                              )}`}
                              type="text"
                              name="Name"
                              id="assignmentName"
                              autoComplete="nope"
                              value={
                                this.state.modalState.Assignment.Name.Value
                              }
                              onChange={this.handleUserInput}
                            />
                            <span className="text-danger">
                              {this.state.modalState.Assignment.Name.ErrorMsg}
                            </span>
                          </FormGroup>
                        </Col>
                      </Row>

                      {this.state.modalState.selectedClassGroupForInsert
                        .subjects.length > 0 && (
                        <React.Fragment>
                          <br />
                          <Row form>
                            <Col md={12}>
                              <FormGroup>
                                <Label for="userRole">
                                  Select all subjects related to this assignment
                                </Label>
                                <ListGroup>{subjects}</ListGroup>
                              </FormGroup>
                            </Col>
                          </Row>
                        </React.Fragment>
                      )}
                    </ModalBody>
                    <ModalFooter>
                      <Button
                        color="primary"
                        onClick={
                          this.state.modalState.editMode === editMode.INSERT
                            ? this.addAssignment.bind(this)
                            : this.updateAssignment.bind(this)
                        }
                        disabled={!this.validateForm()}
                      >
                        {this.state.modalState.editMode === editMode.INSERT
                          ? "Add"
                          : "Update"}
                      </Button>{" "}
                      <Button
                        color="secondary"
                        onClick={this.toggleNewAssignmentModal.bind(this)}
                      >
                        Cancel
                      </Button>
                    </ModalFooter>
                  </Modal>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </>
    );
  }

  getInitialState = () => {
    const initialState = {
      mainState: this.getMainState(),
      modalState: this.getModalState(),
    };

    return initialState;
  };

  getMainState = () => {
    const mainState = {
      assignments: [],
      openModal: false,
      totalResults: 0,
      currentPage: 1,
      itemsPerPage: 10,
      alert: { visible: false, color: "", message: "" },
      StartDateSearch: dateFns.startOfWeek(new Date()),
      EndDateSearch: dateFns.endOfWeek(new Date()),
      courses: [],
      classes: [],
      classGroups: [],
      assignmentTypes: [],
      courseId: "all",
      periodId: "all",
      classId: "all",
      assignmentTypeId: "all",
      classGroupId: "all",
      currentUser: authenticationService.currentUserValue,
    };

    return mainState;
  };

  getEntity = () => {
    const entity = {
      Value: "",
      IsValid: false,
      ErrorMsg: "",
    };
    return entity;
  };

  getAssignment = () => {
    const assignment = {
      Id: this.getEntity(),
      Name: this.getEntity(),
      Date: this.getEntity(),
      MaxGrade: this.getEntity(),
      AssignmentTypeId: this.getEntity(),
      ClasseId: this.getEntity(),
    };
    return assignment;
  };

  getModalState = () => {
    const modalState = {
      Assignment: this.getAssignment(),
      editMode: "",
      cards: [1, 2],
      alert: { visible: false, color: "", message: "" },
      formValid: false,
      assignmentTypes: [],
      classes: [],
      classGroups: [],
      selectedClassGroupForInsert: { subjects: [] },
    };

    return modalState;
  };

  resetModal = () => {
    return new Promise((resolve, reject) => {
      this.setState(
        {
          modalState: this.getModalState(),
        },
        resolve(true)
      );
    });
  };

  onSwitchSubjectChange = (e) => {
    let { modalState } = this.state;

    modalState.selectedClassGroupForInsert.subjects.find(
      (x) => x.id === e.target.id
    ).checked = e.target.checked;

    this.setState({ modalState: modalState });
  };

  handleSearchDateInput = (name, date) => {
    if (date === null) date = new Date();

    let { mainState } = this.state;

    mainState[name] = date;
    mainState.currentPage = 1;

    this.setState({ mainState: mainState }, () => {
      this._refreshAssignments();
    });
  };

  validateField(fieldName, value) {
    let { modalState } = this.state;

    switch (fieldName) {
      case "Name":
        modalState.Assignment.Name.IsValid = value.length >= 1;
        modalState.Assignment.Name.ErrorMsg = modalState.Assignment.Name.IsValid
          ? ""
          : "Name is empty";
        break;
      case "Date":
        modalState.Assignment.Date.IsValid = dateFns.isValid(value);
        modalState.Assignment.Date.ErrorMsg = modalState.Assignment.Date.IsValid
          ? ""
          : "Date is empty";
        break;
      case "MaxGrade":
        modalState.Assignment.MaxGrade.IsValid = value.length >= 1;
        modalState.Assignment.MaxGrade.ErrorMsg = modalState.Assignment.MaxGrade
          .IsValid
          ? ""
          : "MaxGrade is empty";
        break;
      case "AssignmentTypeId":
        modalState.Assignment.AssignmentTypeId.IsValid = value.length >= 1;
        modalState.Assignment.AssignmentTypeId.ErrorMsg = modalState.Assignment
          .AssignmentTypeId.IsValid
          ? ""
          : "AssignmentTypeId is empty";
        break;
      case "ClasseId":
        modalState.Assignment.ClasseId.IsValid = value.length >= 1;
        modalState.Assignment.ClasseId.ErrorMsg = modalState.Assignment.ClasseId
          .IsValid
          ? ""
          : "Subject is empty";
        break;
      default:
        break;
    }

    this.setState({ modalState: modalState });
  }

  validateForm() {
    let subjectValid =
      this.state.modalState.editMode === editMode.INSERT
        ? this.state.modalState.selectedClassGroupForInsert.subjects.filter(
            (x) => x.checked === true
          ).length > 0
        : this.state.modalState.Assignment.ClasseId.IsValid === true;

    return (
      this.state.modalState.Assignment.Name.IsValid === true &&
      this.state.modalState.Assignment.Date.IsValid === true &&
      this.state.modalState.Assignment.MaxGrade.IsValid === true &&
      this.state.modalState.Assignment.AssignmentTypeId.IsValid === true &&
      subjectValid === true
    );
  }

  handleUserInput = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    let { modalState } = this.state;
    modalState.Assignment[name].Value = value;

    this.setState({ modalState: modalState }, () => {
      this.validateField(name, value);
    });
  };

  handleDateInput = (name, date) => {
    let { modalState } = this.state;
    modalState.Assignment[name].Value = date;

    this.setState({ modalState: modalState }, () => {
      this.validateField(name, date);
    });
  };

  handleFocus = (event) => event.target.select();

  handleGradeInput(id, e) {
    let { modalState } = this.state;

    const index = modalState.Assignment.assignmentStudents.findIndex(
      (x) => x.StudentId === id
    );

    const assignmentStudents = [...modalState.Assignment.assignmentStudents];

    const assignmentStudent = assignmentStudents[index];
    assignmentStudent.Grade = e.target.value;

    this.setState({ modalState: modalState });
  }

  handleCourseChange = (selectedOption) => {
    let mainState = this.state.mainState;

    mainState.courseId = selectedOption.value;
    mainState.currentPage = 1;

    this.setState({ mainState: mainState }, () => {
      this._refreshAssignments();
    });
  };

  handlePeriodChange = (selectedOption) => {
    let mainState = this.state.mainState;

    mainState.periodId = selectedOption.value;
    mainState.currentPage = 1;

    this.setState({ mainState: mainState }, () => {
      this._refreshAssignments();
    });
  };

  componentDidMount() {
    this._refreshAssignments().then(() => {
      this.refreshCourses().then(() => {
        this.refreshClassesMainState().then(() => {
          this.refreshClassGroupsMainState().then(() => {
            this.refreshAssignmentTypesMainState();
          });
        });
      });
    });
  }

  refreshCourses = () => {
    return new Promise((resolve, reject) => {
      let mainState = this.state.mainState;

      mainState.courses = [];

      mainState.courses.push({
        value: "all",
        label: "All levels",
      });

      mainState.courseId = "all";

      this.props.tables.courses.map((course, index) =>
        mainState.courses.push({
          value: course.id,
          label: course.name,
        })
      );

      this.setState({ mainState: mainState }, resolve(true));
    });
  };

  refreshClassesMainState = () => {
    return new Promise((resolve, reject) => {
      axios.get("classes?page=1&pageSize=999999").then((response) => {
        let mainState = this.state.mainState;

        mainState.classes = [];

        mainState.classes.push({
          value: "all",
          label: "All subjects",
        });

        mainState.classId = "all";

        response.data.data.items.map((classe, index) =>
          mainState.classes.push({
            value: classe.id,
            label: classe.code,
          })
        );

        this.setState({ mainState: mainState }, resolve(true));
      });
    });
  };

  handleClassMainStateChange = (selectedOption) => {
    let mainState = this.state.mainState;

    mainState.classId = selectedOption.value;
    mainState.currentPage = 1;

    this.setState({ mainState: mainState }, () => {
      this._refreshAssignments();
    });
  };

  handleAssignmentTypeMainStateChange = (selectedOption) => {
    let mainState = this.state.mainState;

    mainState.assignmentTypeId = selectedOption.value;
    mainState.currentPage = 1;

    this.setState({ mainState: mainState }, () => {
      this._refreshAssignments();
    });
  };

  handleClassGroupMainStateChange = (selectedOption) => {
    let mainState = this.state.mainState;

    mainState.classGroupId = selectedOption.value;
    mainState.currentPage = 1;

    this.setState({ mainState: mainState }, () => {
      this._refreshAssignments();
    });
  };

  toggleNewAssignmentModal = () => {
    return new Promise((resolve, reject) => {
      let mainState = this.state.mainState;
      mainState.openModal = !mainState.openModal;
      this.setState(
        {
          mainState: mainState,
        },
        resolve(true)
      );
    });
  };

  addAssignment() {
    let assignments = [];
    this.state.modalState.selectedClassGroupForInsert.subjects
      .filter((x) => x.checked === true)
      .map((subject, index) =>
        assignments.push({
          Name: this.state.modalState.Assignment.Name.Value,
          Date: dateFns.format(
            this.state.modalState.Assignment.Date.Value,
            "yyyy-MM-dd"
          ),
          MaxGrade: this.state.modalState.Assignment.MaxGrade.Value,
          AssignmentType: {
            Id: this.state.modalState.Assignment.AssignmentTypeId.Value,
          },
          Classe: { Id: subject.id },
        })
      );

    axios.post("assignments", assignments).then((response) => {
      this.toggleNewAssignmentModal().then(() => {
        this._refreshAssignments().then(() => {
          this.resetModal();
        });
      });
    });
  }

  updateAssignment() {
    let assignment = {
      Id: this.state.modalState.Assignment.Id.Value,
      Name: this.state.modalState.Assignment.Name.Value,
      Date: dateFns.format(
        this.state.modalState.Assignment.Date.Value,
        "yyyy-MM-dd"
      ),
      MaxGrade: this.state.modalState.Assignment.MaxGrade.Value,
      AssignmentType: {
        Id: this.state.modalState.Assignment.AssignmentTypeId.Value,
      },
      Classe: { Id: this.state.modalState.Assignment.ClasseId.Value },
    };

    axios.put("assignments", assignment).then((response) => {
      this.resetModal().then(() => {
        this.toggleNewAssignmentModal().then(() => {
          this._refreshAssignments();
        });
      });
    });
  }

  refreshAssignmentTypes = () => {
    return new Promise((resolve, reject) => {
      let { modalState } = this.state;

      this.props.tables.assignmentTypes.map((assignmentType, index) =>
        modalState.assignmentTypes.push({
          value: assignmentType.id,
          label: assignmentType.name,
        })
      );

      this.setState(
        {
          modalState: modalState,
        },
        resolve(true)
      );
    });
  };

  refreshAssignmentTypesMainState = () => {
    return new Promise((resolve, reject) => {
      let { mainState } = this.state;

      mainState.assignmentTypes.push({
        value: "all",
        label: "All assignment types",
      });

      mainState.assignmentTypeId = "all";

      this.props.tables.assignmentTypes.map((assignmentType, index) =>
        mainState.assignmentTypes.push({
          value: assignmentType.id,
          label: assignmentType.name,
        })
      );

      this.setState(
        {
          mainState: mainState,
        },
        resolve(true)
      );
    });
  };

  refreshClasses = () => {
    return new Promise((resolve, reject) => {
      axios.get("classes?page=1&pageSize=999999").then((response) => {
        let { modalState } = this.state;

        response.data.data.items.map((classe, index) =>
          modalState.classes.push({
            value: classe.id,
            label: classe.code,
          })
        );

        this.setState(
          {
            modalState: modalState,
          },
          resolve(true)
        );
      });
    });
  };

  refreshClassGroups = () => {
    return new Promise((resolve, reject) => {
      axios.get("classgroups").then((response) => {
        let { modalState } = this.state;

        let subjects = [];
        response.data.data.map((classGroup, index) => {
          subjects = [];
          classGroup.subjects.map((classe, index) =>
            subjects.push({
              id: classe.id,
              code: classe.code,
              checked: true,
            })
          );

          modalState.classGroups.push({
            value: classGroup.id,
            label: classGroup.groupCode,
            subjects: subjects,
          });
        });

        this.setState(
          {
            modalState: modalState,
          },
          resolve(true)
        );
      });
    });
  };

  refreshClassGroupsMainState = () => {
    return new Promise((resolve, reject) => {
      axios.get("classgroups").then((response) => {
        let { mainState } = this.state;

        mainState.classGroups.push({
          value: "all",
          label: "All classes",
        });

        mainState.classGroupId = "all";

        response.data.data.map((classGroup, index) => {
          mainState.classGroups.push({
            value: classGroup.id,
            label: classGroup.groupCode,
          });
        });

        this.setState(
          {
            mainState: mainState,
          },
          resolve(true)
        );
      });
    });
  };

  openModalAssignment(mode, id) {
    let { modalState } = this.state;
    modalState.editMode = mode;

    this.setState({ modalState: modalState });

    this.refreshAssignmentTypes().then(() => {
      if (mode === editMode.INSERT) {
        this.refreshClassGroups().then(() => {
          this.toggleNewAssignmentModal().then(() => {
            let newDate = new Date();

            modalState.Assignment.Date.Value = newDate;
            modalState.Assignment.Date.IsValid = true;

            this.setState({
              modalState: modalState,
            });
          });
        });
      } else {
        this.refreshClasses().then(() => {
          axios
            .get("assignments/byid?id=" + id + "&fillStudents=false")
            .then((response) => {
              modalState.Assignment.Id.Value = response.data.data.id;

              modalState.Assignment.Name.Value = response.data.data.name;
              modalState.Assignment.Name.IsValid = true;

              modalState.Assignment.Date.Value = dateFns.parseISO(
                response.data.data.date
              );
              modalState.Assignment.Date.IsValid = true;

              modalState.Assignment.MaxGrade.Value =
                response.data.data.maxGrade;
              modalState.Assignment.MaxGrade.IsValid = true;

              modalState.Assignment.AssignmentTypeId.Value =
                response.data.data.assignmentType.id;
              modalState.Assignment.AssignmentTypeId.IsValid = true;

              modalState.Assignment.ClasseId.Value =
                response.data.data.classe.id;
              modalState.Assignment.ClasseId.IsValid = true;

              this.setState(
                {
                  modalState: modalState,
                },
                () => {
                  this.toggleNewAssignmentModal();
                }
              );
            });
        });
      }
    });
  }

  async deleteAssignment(id) {
    let result = await confirm({
      title: <>Warning</>,
      message: "Are you sure you wish to delete this item?",
    });

    if (result) {
      axios.delete("assignments/" + id).then(() => {
        this._refreshAssignments();
      });
    }
  }

  _refreshAssignments = (pageNumber) => {
    return new Promise((resolve, reject) => {
      let page = pageNumber || 1;
      let itemsPerPage = this.state.mainState.itemsPerPage;
      let startDate = dateFns.format(
        this.state.mainState.StartDateSearch,
        "yyyy-MM-dd"
      );
      let endDate = dateFns.format(
        this.state.mainState.EndDateSearch,
        "yyyy-MM-dd"
      );
      let courseId = this.state.mainState.courseId;
      let classId = this.state.mainState.classId;
      let assignmentTypeId = this.state.mainState.assignmentTypeId;

      let periodId = this.state.mainState.periodId;
      if (periodId === "all") periodId = "";

      let classGroupLabel = "";
      if (this.state.mainState.classGroupId !== "all") {
        classGroupLabel = this.state.mainState.classGroups.find(
          (x) => x.value === this.state.mainState.classGroupId
        ).label;
      }

      axios
        .get(
          "assignments?" +
            "startDate=" +
            startDate +
            "&endDate=" +
            endDate +
            "&courseId=" +
            courseId +
            "&period=" +
            periodId +
            "&classId=" +
            classId +
            "&assignmentTypeId=" +
            assignmentTypeId +
            "&classGroup=" +
            classGroupLabel +
            "&page=" +
            page +
            "&pageSize=" +
            itemsPerPage
        )
        .then((response) => {
          let { mainState } = this.state;

          mainState.assignments = [];
          response.data.data.items.map((assignment, index) =>
            mainState.assignments.push({
              id: assignment.id,
              name: assignment.name,
              date: FormatFullDateList(assignment.date),
              maxGrade: assignment.maxGrade,
              assignmentTypeId: assignment.assignmentType.id,
              assignmentTypeName: assignment.assignmentType.name,
              classId: assignment.classe.id,
              classCode: assignment.classe.code,
              campusName: assignment.classe.campus.name,
            })
          );

          mainState.currentPage = pageNumber;
          mainState.totalResults = response.data.data.totalCount;

          this.setState({ mainState: mainState }, resolve(true));
        });
    });
  };

  onCloseModal = () => {
    this.resetModal();
  };

  errorClass(error) {
    return error.length === 0 ? "" : "is-invalid";
  }

  setCurrentPage(pageNumber) {
    let { mainState } = this.state;
    mainState.currentPage = pageNumber;
    this.setState({ mainState: mainState });
  }

  handleAssignmentTypeChange = (selectedOption) => {
    let { modalState } = this.state;

    modalState.Assignment.AssignmentTypeId.Value = selectedOption.value;
    modalState.Assignment.Name.Value = selectedOption.label;
    modalState.Assignment.Name.IsValid = true;

    this.setState({ modalState: modalState }, () => {
      this.validateField("AssignmentTypeId", selectedOption.value);
    });

    document.getElementById("assignmentName").focus();
  };

  handleClasseChange = (selectedOption) => {
    let { modalState } = this.state;

    modalState.Assignment.ClasseId.Value = selectedOption.value;

    this.setState({ modalState: modalState }, () => {
      this.validateField("ClasseId", selectedOption.value);
    });

    document.getElementById("assignmentMaxGrade").focus();
  };

  handleClassGroupChange = (selectedOption) => {
    let { modalState } = this.state;

    modalState.selectedClassGroupForInsert = selectedOption;

    this.setState({ modalState: modalState });

    document.getElementById("assignmentMaxGrade").focus();
  };

  periodOptions = [
    { value: "all", label: "All periods" },
    { value: "M", label: "Morning" },
    { value: "A", label: "Afternoon" },
    { value: "E", label: "Evening" },
    { value: "MT", label: "Mon/Tue" },
    { value: "WT", label: "Wed/Thu" },
    { value: "HW", label: "Wed" },
  ];
}

export default Assignment;
